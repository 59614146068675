import { useState } from "react";
import { useSelector } from "react-redux";

import SearchIcon from "@mui/icons-material/Search";

import {
  Grid,
  Typography,
  InputAdornment,
  TextField,
  Button
} from "@mui/material";

import './styles.css'

interface IFilterProps {
  elementsToFilter: any[];
  adictionalFilters?: any[];
  filterField: string;
  setFilterResultFunction;
  specificFilterFunction: any | undefined;
  titleTable?: string;
  handleCleanSearh?: () => void;
  filterVisible: true | false;
  setTextSearch?: any;
  container?: any; 
}

export default function Searchbar(props: IFilterProps) {
  const { user } = useSelector((state: any) => state.authentication);
  const [statusButton, setStatusButton] = useState(true);
  const [filterString, setFilterString] = useState("");

  const titleTable = props?.titleTable;
  const elementsToFilter: any[] = props.elementsToFilter;
  const filterField: string = props.filterField;
  const setFilterFunction = props.setFilterResultFunction;
  const setTextSearch = props.setTextSearch;
  const specificFilterFunction: any | undefined = props.specificFilterFunction;
  const adictionalFilters: any[] | undefined = props.adictionalFilters;

  function enableButtons(e) {
    if (e.target.value.length > 0) {
      setStatusButton(false);
    } else {
      setStatusButton(true);
      setFilterFunction(elementsToFilter);
    }
    setFilterString(e.target.value);
    setTextSearch(e.target.value)
    // props.queryString(e.target.value);  // Esta prop permite alterar o valor na tela de listagem
  }

  const filter = (param: string) => {
    if (specificFilterFunction) {
      specificFilterFunction(param);
    } else {
      if (param) {
        let data: any[] = elementsToFilter.filter((element) => {
          const getElement =
            user.type === "teacher" ? element.assessment : element;

          return (
            getElement[filterField] !== null &&
            getElement[filterField].toLowerCase().includes(param.toLowerCase())
          );
        });

        setFilterFunction(data);
      }
    }
  };

  const otherFilters = (aditionals: any) => {
    return aditionals.map((filter, key) => {
      return (
        <Grid item md={3}>
          {filter}
        </Grid>
      )
    })
  }

  return (
    <div className="searchbar_container">
      <Typography className="titleInfo">
        {titleTable ?? ""}
      </Typography>

      <div>
        {props?.container}
      </div>

      <div className="divSearchCss">
        {props.filterVisible == true ?
          <Grid container spacing={1}>
            <Grid item sm={adictionalFilters ? 3 : 10} >
              <TextField
                fullWidth
                className="textfield"
                value={filterString}
                variant="outlined"
                placeholder="Pesquisar"
                onChange={(e) => enableButtons(e)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            {adictionalFilters
              ? otherFilters(adictionalFilters)
              : null}

            <Grid item md={2} >
              <div style={{ display: "flex", justifyContent: "flex-start", gap: 10 }}>
                <Button
                  variant="contained"
                  size="large"
                  //disabled={statusButton}
                  onClick={(e) => filter(filterString)}
                  style={{ width: "100%" }}
                >
                  Pesquisar
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  // className="button secondary"
                  //disabled={statusButton}
                  onClick={(e) => {
                    if (props.handleCleanSearh) {
                      setFilterString("");
                      props.handleCleanSearh();
                    } else {
                      setFilterString("");
                      setFilterFunction(elementsToFilter);
                      setStatusButton(true);
                    }
                  }}
                  style={{ width: "100%" }}
                >
                  Limpar
                </Button>
              </div>
            </Grid>
          </Grid>
          : null}
      </div>
    </div>
  );
}