import { Assessment, AssessmentExam } from "services/admin/Interfaces/Types";
import api from "services/api";

export default class StudentAnswersServices {
  public static async register(props: any) {
    const result = await api.post(
      "/assessments/studentanswers/",
      JSON.stringify(props),
      { headers: { "Content-Type": "application/json" } }
    );
    return result;
  }

  public static async getAllStudentsAnswers() {
    return api.get("/assessments/studentanswers/");
  }

  public static async deleteStudentAnswers(props: any) {
    return api.delete("/assessments/studentanswers/" + props.id + "/");
  }

  public static async deleteStudentAnswersEvaluation(id: number) {
    return api.delete("/assessments/studentanswersevaluation/" + id + "/");
  }

  public static async findAll(params: any, custom_url?: string) {
    return api.get(custom_url ? custom_url : "/assessments/studentanswers/", {
      params,
    });
  }

  public static async getTranscribedAnswers(params: any, custom_url?: string) {
    return api.get<{ results: any[], count?: number }>(
      custom_url ? custom_url : "/assessments/transcribedanswers/",
      {
        params,
      }
    );
  }

  public static async filterAnswers(
    assessmentId: number,
    itemId: number,
    params: { answer: string; evaluation__rubric: string; ordering: string; offset?: string | number; evaluated?: string }
  ) {
    return api.get(
      `/assessments/assessments/${assessmentId}/answers/${itemId}/`,
      { params }
    );
  }

  public static async getCentroids(id: number) {
    return api.get(`/careca/clustering/centroids/?item_id=${id}`);
  }

  public static async SendCluster(data: any) {
    return api.post("/careca/clustering/propagate/", data);
  }
}
