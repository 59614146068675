import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import remarkMath from "remark-math";
import rehypeRaw from 'rehype-raw';
import rehypeKatex from "rehype-katex";

import "katex/dist/katex.min.css"

interface IMarkdownConvertProps {
    textInMarkdown: string;
}

export function MarkdownConvert({ textInMarkdown }: IMarkdownConvertProps) {
    return (
        <ReactMarkdown 
            children={textInMarkdown} 
            remarkPlugins={[remarkGfm, remarkMath]} 
            rehypePlugins={[rehypeRaw, rehypeKatex]}/>
    )
}