import React, { useEffect, useState } from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@mui/material/TableContainer";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TitlePage from "components/Title/Title";
import Searchbar from "components/Searchbar/Searchbar";
import TaskCorrectionList from "./TaskCorrectionList";
import { useHistory, useLocation, useParams } from "react-router-dom";
import TaskServices from "store/reducers/Admin/AsyncActions/Tasks/task.service";
import { Button, Checkbox } from "@mui/material";
import { useSelector } from "react-redux";
import { MarkdownConvert } from "components/MarkdownConvert";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TableEmptyState from "components/TableEmptyState";
import StateBread from "utils/stateBreadcrumb/stateBreadcrumb";
import AccordionTransition from "components/AccordinItem";
import CardItens from "components/CardItens";

export default function TaskDetail(props) {  
  const history = useHistory();
  const { user } = useSelector((state: any) => state.authentication);
  const { idAssessment, examId, idTask } = useParams<{
    idAssessment: string;
    examId: string;
    idTask: string;
  }>();

  const [selected, setSelected] = useState<string[]>([]);
  const [items, setItems] = useState<any[]>([]);
  const [itemsFiltered, setItemsFiltered] = useState<any[]>([]);
  const [searchText, setSearchText] = useState<string | null>(null);

  const [tasks, setTasks] = useState<any>(null);
  const taskTitle = props.location.state?.title;
  const taskId = props.location.state?.id;
  const taskItems = props.location.state?.items;
  const exameItemState = props.location.state?.examState;
  const taskState = props.location.state;
  const assessmentSelectedTeacher =
    props.location.state?.assessmentSelectedTeacher;



  const title = taskTitle
  const location = useLocation();

  const content = props.location.state?.content;
  StateBread({ path: location.pathname, title: title })




  useEffect(() => {
    getTask();
  }, []);


  useEffect(() => {
    getItemWithoutChoices();
  }, [tasks]);



  const getTask = () => {
    TaskServices.getById(Number(idTask)).then(({ data }: any) => {
      setTasks(data);
    });
  };


  const getItemWithoutChoices = () => {
    if (tasks?.items.length > 0) {
      let getItems = [];
      if (user.type === "teacher") {
        getItems = tasks?.items.filter((itemTask) =>
          assessmentSelectedTeacher.item.includes(itemTask.id)
        );
      } else {
        getItems = tasks?.items
      }

      getItems.forEach((tItem: any) => {
        if (tItem.choices.length == 0) {
          setItems((i) => [...i, tItem]);
          setItemsFiltered((i) => [...i, tItem]);
        }
      });
    }
  };

  const handleClick = (item: any) => {
    history.push({
      pathname: `/admin/assessments/${idAssessment}/exams/${examId}/tasks/${tasks.id}/items/${item.id}/`      
    });
  };

  const truncateText = (text: String, maxSize: number): String => {
    if (text.length < maxSize) {
      return text;
    } else {
      return text.substring(0, maxSize) + " ...";
    }
  };

  return (
    <div className="main">
      <div className="main-content">
        <TitlePage
          title={taskTitle}
          goBackTo={`/admin/assessments/${idAssessment}/exams/${examId}/`}
          stateRouteData={{ ...exameItemState }}
          container={<AccordionTransition text={<MarkdownConvert textInMarkdown={content as string} />} />}
        />
        <Searchbar
          elementsToFilter={items}
          setTextSearch={setSearchText}
          setFilterResultFunction={setItemsFiltered}
          filterField={"title"}
          specificFilterFunction={undefined}
          titleTable="Itens"
          // numTotalRecordsTable={items.length + " registro(s) encontrado(s)"}
          filterVisible={true}
        // container={<CardItensResume />}
        />
        {/* <ActionButtonEvaluator disabledButton={statusButtons} /> */}





        {itemsFiltered.length > 0 ?
          <div>
            {itemsFiltered.map((item) => {


              return (
                <>

                  <CardItens color={"primary"} index={item.title} title={item.content} button={<Button color="primary" variant="contained" endIcon={<ArrowForwardIcon />}
                    onClick={(event: any) => handleClick(item)}>
                    Corrigir
                  </Button>} />
                </>
              );
            })
            }

          </div>
          : <div>
            <TableEmptyState text={searchText} />
          </div>
        }
      </div>
    </div>

  );
}