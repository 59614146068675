interface IBread {
    path: string;
    title: string;
}


export default function StateBread(value: IBread) {


    const current = localStorage.getItem('breadcrumbs')
    let listaDeObjetos: any = []

    if (current) {

        listaDeObjetos = JSON.parse(current);
        const objetoExistente = listaDeObjetos.find(objeto => {
            return objeto.path === value.path;
        });

        if (!objetoExistente) {
            localStorage.setItem('breadcrumbs', "")
            listaDeObjetos.push(value)
            localStorage.setItem('breadcrumbs', JSON.stringify(listaDeObjetos))
        }

    } else {
        localStorage.setItem('breadcrumbs', "")
        listaDeObjetos.push(value)
        localStorage.setItem('breadcrumbs', JSON.stringify(listaDeObjetos))
    }

}
