import { Assessment, AssessmentExam } from "services/admin/Interfaces/Types";
import api from "services/api";

export default class StudentAnswersEvaluationServices {


	public static async register(props: any) {
		return api.post('/assessments/studentanswersevaluation/', JSON.stringify(props), { headers: { 'Content-Type': 'application/json', } });
	}

	public static async update(id: number, props: any) {
		return api.put('/assessments/studentanswersevaluation/'+id+'/', JSON.stringify(props), { headers: { 'Content-Type': 'application/json', } });
	}


	public static async delete(props: any) {
		return api.delete("/assessments/studentanswersevaluation/" + props.id + "/");
	}

	public static async findAll() {
		return api.get('/assessments/studentanswersevaluation/');
	}

	public static async get(params: any) {
		return api.get('/assessments/studentanswersevaluation/', { params });
	}

	public static async manyRegister(props: any) {
		return api.post('/assessments/bulk_studentanswersevaluations/', JSON.stringify(props), { headers: { 'Content-Type': 'application/json', } });
	}

	
}
