export function orderArrayById(elementos) {
    if (elementos.length > 0) {
      const orderedArray = [...elementos];
  
      orderedArray.sort((a, b) => (a.id as number) - (b.id as number));
      
      return orderedArray;
    } else {
      return elementos;
    }
};