import { Route, Switch } from "react-router-dom";
import AssessmentsList from "../Evaluator/AssessmentsList";
import AssessmentsExams from "../Evaluator/AssessmentsDetail";
import ExamsList from "../Evaluator/ExamsList";
import TasksList from "../Evaluator/TasksList";
import React, { useEffect } from "react";
import ItemCorrection from "../Evaluator/ItemCorrection";
import TaskDetail from "../Evaluator/TaskDetail";
import TranscriptList from "../Transcriber/TranscriptList";
import { Transcription } from "../Transcriber/Transcription";
import { TRANSCRIPTIONS_ROUTE } from "./constants";
import Home from "../Home";
import ItemClusters from "../Evaluator/ItemClusters";
export default function AdminContent(): React.ReactElement {

  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/admin/assessments/" exact component={AssessmentsList} />
      <Route
        path="/admin/assessments/:idAssessment/"
        exact
        component={AssessmentsExams}
      />
      <Route
        path="/admin/assessments/:idAssessment/exams/:idExam/"
        exact
        component={ExamsList}
      />
      <Route path="/admin/tasks/" exact component={TasksList} />
      <Route
        path="/admin/assessments/:idAssessment/exams/:examId/tasks/:idTask/"
        exact
        component={TaskDetail}
      />
      <Route
        path="/admin/assessments/:idAssessment/exams/:examId/tasks/:idTask/items/:idItem/"
        exact
        component={ItemCorrection}
      />
      <Route path="/admin/assessments/transcription/answer/" exact component={Transcription} />

      <Route path={TRANSCRIPTIONS_ROUTE} exact component={TranscriptList} />


      <Route
        path="/admin/assessments/:idAssessment/exams/:examId/tasks/:idTask/items/:itemId/clusters"
        exact
        component={ItemClusters}
      />
    </Switch>
  );
}
