import logoCenpe from 'assets/logos/logo.png'

import './styles.css'

interface ICoverEvaluation {
    title_assessment: string;
    description_assessment: string;
}

export function CoverEvaluation(props: ICoverEvaluation) {
    return (
        <div className="cover_evaluation_content">
            <div className="cover_evaluation_header">
                <div>
                    <span>Avaliação Formativa</span>
                    <span>{props.title_assessment}</span>
                    <span>{props.description_assessment}</span>
                </div>

                <div>
                    <img src={logoCenpe} alt="Logo do CENPE" />
                </div>
            </div>
            
            <div className='cover_evaluation_register_student'>
                <div style={{ width: '100%' }}>
                    NOME: <div className='line'/>
                </div>

                <div style={{ width: '45%' }}>
                    TURMA: <div className='line'/>
                </div>

                <div style={{ width: '45%' }}>
                    TURNO: <div className='line'/>
                </div>

                <div style={{ width: '100%' }}>
                    ESCOLA: <div className='line'/>
                </div>

                <div style={{ width: '100%' }}>
                    MATRÍCULA DO ALUNO: <div className='line'/>
                </div>
            </div>

            <div style={{ fontSize: '12pt' }}>
                <span>Caro(a) aluno(a),</span>

                    <ul style={{ listStyle: 'none' }}>
                        <li>
                            O objetivo desta avaliação é identificar os conhecimentos e as habilidades em que você progrediu e,
                            principalmente, aqueles em que você tem mais dificuldades: com essas informações precisas, o(a) professor(a) vai
                            apoiá-lo(a) ainda mais a avançar no aprendizado.
                        </li>

                        <li>
                            Este caderno apresenta dois blocos de questões de Língua Portuguesa e de Matemática.
                        </li>

                        <li>
                            Ao finalizar o primeiro bloco de Língua Portuguesa e o primeiro bloco de Matemática, você terá um intervalo
                            e depois poderá responder às questões do segundo bloco das duas disciplinas.
                        </li>

                        <li>
                            Leia atentamente os textos e as questões antes de tentar resolvê-las.
                        </li>

                        <li>
                            Escreva suas respostas apenas sobre as linhas indicadas, de forma legível e usando caneta azul ou preta.
                        </li>

                        <li>
                            Nas questões de Matemática, use os campos após cada questão para escrever sua resposta e explicar o
                            raciocínio que você desenvolveu.
                        </li>

                        <li>
                            No teste de Matemática, há espaços para rascunho: use esses espaços para fazer os cálculos e desenhar as
                            figuras necessárias para a resolução das questões.
                        </li>
                    </ul>
            
                <span>Boa Avaliação!</span>
            </div>
        </div>
    )
}