import { BorderColor, Height } from '@mui/icons-material';
import { createTheme } from '@mui/material';

export const theme = createTheme({
    typography: {
        fontFamily: 'Poppins, Roboto',
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    transition: 'background-color 0.2s',
                    fontFamily: 'Poppins',
                    ':hover': {
                        boxShadow: 'none',
                    },
                    height: "41px"
                },
                containedPrimary: {
                    fontFamily: 'Poppins',
                    color: "var(--neutral-1)",
                    backgroundColor: "var(--primary-regular)",
                    transition: 'all 0.2s linear',
                    '&:hover': {
                        backgroundColor: "var(--primary-regular)",
                        filter: 'brightness(0.9)',
                        color: "var(--neutral-1)",
                    }
                },
                containedSecondary: {
                    fontFamily: 'Poppins',
                    color: "var(--primary-regular)",
                    backgroundColor: "var(--primary-lighter)",
                    transition: 'all 0.2s linear',
                    '&:hover': {
                        color: "var(--primary-regular)",
                        backgroundColor: "var(--primary-lighter)",
                        filter: 'brightness(0.9)',
                    }
                },
                containedWarning: {
                    fontFamily: 'Poppins',
                    color: "var(--neutral-1)",
                    backgroundColor: "var(--wheat-regular)",
                    transition: 'all 0.2s linear',
                    '&:hover': {
                        backgroundColor: "var(--wheat-regular)",
                        filter: 'brightness(0.9)',
                        color: "var(--neutral-1)",
                    }
                },
                outlinedPrimary: {
                    fontFamily: 'Poppins',
                    color: "#1D2432",
                    backgroundColor: "#FFFFFF",
                    transition: 'all 0.2s linear',
                    borderColor: "#CACDD5",
                    fontWeight: "600",
                    '&:hover': {
                        backgroundColor: "#CACDD5",
                        filter: 'brightness(0.9)',
                        color: "#FFFFFF",
                        borderColor: "#CACDD5"
                    }
                },
                sizeLarge: {
                    padding: '14px'
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-root': {
                        fontSize: '14px',
                    },

                    '& .MuiInputBase-input': {
                        height: '10px',
                        borderColor: ""
                    },
                },
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    fontFamily: 'Poppins',                                                            
                    '&.Mui-selected': {
                        backgroundColor: "#1a367c !important",          
                        color: "white"              
                    },
                },
            },
        },
    }
});
