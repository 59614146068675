import { useEffect, useState, useRef } from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  Avatar,
  Box,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from "@mui/material";

import Searchbar from "components/Searchbar/Searchbar";

import VisibilityIcon from "@mui/icons-material/Visibility";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import { MarkdownConvert } from "components/MarkdownConvert";

import StudentAnswersServices from "store/reducers/Admin/AsyncActions/studentsAnswers/studentanswers.service";
import RubricServices from "./../../../store/reducers/Admin/AsyncActions/rubric/rubric.service";
import AuthenticationServices from "store/reducers/Authentication/AsyncActions/authentication.services";

import {
  StyledTableCell,
  StyledTableRow,
  useStyles,
} from "./styles/ItemCorrectionStyle";

import { IRegisterProps } from "store/reducers/Authentication/AsyncActions/types";
import { IStudantAnswer } from "store/reducers/Admin/Actions/StudantAnswerEvaluation copy/Actions/types";
import TitlePage from "components/Title/Title";
import { useHistory, useParams } from "react-router-dom";
import React from "react";
import { PrintAvaluation } from "components/PrintAvaluation";
import ItensServices from "store/reducers/Admin/AsyncActions/Itens/itens.service";

import { toast } from "react-toastify";



export const ALPHABET = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];


export interface IRubric {
  id: number;
  name: string;
  order: number;
}

export interface IEvaluationForm {
  rubric: string;
  feedback: string;
}

export interface IStudantAnswerList {
  status: boolean;
  studantAnswer: IStudantAnswer;
  rubric: string;
  feedback: string;
}

export default function ItemClusters(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);


  const handleClose = () => {
    setOpen(false);
  };

  const [openDialogViewQuestion, setOpenDialogViewQuestion] = useState(false);
  const [openDialogViewAssessment, setOpenDialogViewAssessment] = useState(false);
  const [statusFilter, setStatusFilter] = useState<string>("")

  const [buttonResult, setButtonResult] = useState(true);



  const [rubrics, setRubrics] = useState<any[]>([]);
  const [userContext, setUserContext] = useState<IRegisterProps>();
  const [answersEvaluation, setAnswersEvaluation] = useState<
    any[]
  >([]);

  const [count, setCount] = useState<any>(0);
  const [answers, setAnswers] = useState<IStudantAnswer[]>([]);
  const [answersMeta, setAnswersMeta] = useState<any>({
    count: null,
    next: null,
    previous: null,
  });

  const [isButtonEnabled, setIsButtonEnabled] = useState(true);
  const [pending, setPending] = useState<boolean>(false);

  const openSelect = useRef<any>(null);

  const taskState = { ...props.location.state };
  
  const [item, setItem] = useState<any>(null);

  const history = useHistory();


  const { idAssessment, examId, idTask, itemId } = useParams<{
    idAssessment: string;
    examId: string;
    idTask: string;
    itemId?: any;
  }>();


  useEffect(() => {
    const allItemsHaveRubric = answersEvaluation.every(item => item.rubric !== null && item.rubric !== undefined && item.rubric !== '');
    setIsButtonEnabled(!allItemsHaveRubric);
  }, [answersEvaluation]);


  const handleChangeMultipleChoices = async (event, answer: IStudantAnswerList) => {

    const newState = answersEvaluation.map((obj) => {
      if (obj === answer) {
        return { ...obj, [event.target.name]: event.target.value };
      }
      return obj;
    });    
    const item = document.getElementById(`item--${event.target.value}`);
    item?.click();
    item?.blur();    
    setAnswersEvaluation(newState);
  };
  const [paramsFilterTable, setParamsFilterTable] = useState<{
    answer: any,
    rubric: any,
    ordering: any,
    offset?: any
    evaluated?: any
  }>({
    answer: "",
    rubric: "0",
    ordering: "",
    offset: "",
    evaluated: "0",
  });

  const getCentroids = () => {
    StudentAnswersServices.getCentroids(itemId).then((response) => {
      let result = response.data
      setAnswersEvaluation(result)
    }
    ).catch(() => {
      handleClose()
    })
  }



  useEffect(() => {
    // getAnswersByItem();
    filterAnswers(paramsFilterTable);
    getRubrics();
    getUserID();
    getCentroids()
  }, []);

  useEffect(() => {
    getAnswersEvaluation();
  }, [answers]);

  useEffect(() => {
    filterAnswers(paramsFilterTable);
    // getRubrics();
    getAnswersEvaluation();

  }, [count]);


  useEffect(() => {
    filterAnswers(paramsFilterTable);
  }, [statusFilter])

  useEffect(() => {
    getItem()
  }, [])


  const getItem = () => {
    ItensServices.getByNumberId(Number(itemId)).then(({ data }: any) => {
      setItem(data);
    });
  };


  const getAnswersEvaluation = async () => {
    if (rubrics.length > 0) {
      const newState = answers.map((item) => {
        if (item.evaluation != null) {
          return {
            studantAnswer: item,
            status: true,
            rubric: rubrics[item.evaluation.rubric - 1]["name"],
            feedback: item.evaluation.feedback,
          };
        }
        return {
          studantAnswer: item,
          status: false,
          rubric: "0",
          feedback: "",
        };
      });
      // setAnswersEvaluation(newState);
    }
  };

  const filterAnswersByAnswerText = (answerText: string) => {
    const newParamsFilterTable = { ...paramsFilterTable, offset: "", answer: answerText };

    setParamsFilterTable(newParamsFilterTable);
    setStatusFilter("");

    if (answerText.trim().length > 0) filterAnswers(newParamsFilterTable);
  };

  const filterByEvaluatedFlag = (valueFilter: string) => {

    var newParamsFilterTable = { ...paramsFilterTable, evaluated: "" };

    if (valueFilter === 'true') {
      newParamsFilterTable = { ...paramsFilterTable, evaluated: "true" };
    } else if (valueFilter === 'false') {
      newParamsFilterTable = { ...paramsFilterTable, evaluated: "false" };
    }

    setParamsFilterTable(newParamsFilterTable);
    filterAnswers(newParamsFilterTable);

  }

  const filterAnswersByRubric = (rubric: string) => {
    const newParamsFilterTable = { ...paramsFilterTable, rubric };
    setParamsFilterTable(newParamsFilterTable);
    filterAnswers(newParamsFilterTable);
  };

  const orderingAnswers = (orderingBy: string) => {
    const newParamsFilterTable = {
      ...paramsFilterTable,
      ordering: orderingBy === paramsFilterTable.ordering ? "" : orderingBy,
    };
    setParamsFilterTable(newParamsFilterTable);
    filterAnswers(newParamsFilterTable);
  };

  const handleCleanSearh = () => {
    const newParamsFilterTable = { answer: "", rubric: "0", ordering: "", evaluated: "0" };
    setParamsFilterTable(newParamsFilterTable);
    filterAnswers(newParamsFilterTable);
  };

  function extractOffsetValue(urlString) {
    const regex = /offset=(\d+)/;
    const match = urlString.match(regex);

    if (match && match[1]) {
      return match[1];
    } else {
      return "";
    }
  }

  const filterAnswers = async (params: {
    answer: string;
    rubric: string;
    ordering: string;
    evaluated?: string
  }) => {
    const param_fixed = {
      answer: params.answer,
      evaluation__rubric: params.rubric !== "0" ? params.rubric : "",
      ordering: params.ordering,
      evaluated: params.evaluated !== "0" ? params.evaluated : ""
    };

    setPending(true);
    StudentAnswersServices.filterAnswers(
      Number(idAssessment),
      itemId,
      { ...param_fixed, offset: extractOffsetValue(statusFilter) },
    ).then(({ data }: any) => {
      setAnswers(data.results);
      setAnswersMeta({
        count: data.count,
        next: data.next,
        previous: data.previous,
      });
      setPending(false);

    });
  };

  const getUserID = () => {
    AuthenticationServices.getIdUser().then((result: any) => {
      setUserContext(result.data);
    });
  };


  const getRubrics = async () => {
    RubricServices.findAll({ item: itemId }).then(({ data }: any) => {
      if (data.results) {
        var sorted_rubrics = data.results.sort(function (a, b) {
          return a.id - b.id;
        });
      }

      setRubrics(sorted_rubrics);
    });
  };


  const handleAcoordionQuestionClick = () => {
    setOpenDialogViewQuestion(!openDialogViewQuestion);
  };


  const sendAll = () => {

    let payload: any = {
      '0': [],
      '1': [],
      '2': []
    };

    answersEvaluation.forEach((answer: any) => {
      switch (answer.rubric) {
        case "Insuficiente":
          payload['0'].push(answer?.answer_id);
          break;
        case "Parcialmente suficiente":
          payload['1'].push(answer?.answer_id);
          break;
        case "Suficiente":
          payload['2'].push(answer?.answer_id);
          break;
      }
    });

    const data = {
      "item_id": itemId,
      "evaluations": payload
    }



    StudentAnswersServices.SendCluster(data).then((response) => {
      let result = response.data

      if (result?.message === 'ok') {
        setButtonResult(false)
        setIsButtonEnabled(true)

        toast.success("Correções enviadas !")

      }
    }
    ).catch((error: any) => {
      toast.error("Erro ao tentar enviar a correção !")

    })
  }


  const handleResultView = () => {
    const url = props.location.pathname    

    console.log(url)
    history.push(url.replace("/clusters",""))
  }

  const rubricFilter = () => {
    return (
      <Box>
        <Select
          ref={openSelect}
          defaultValue={"0"}
          name="rubric"
          sx={{ width: "300px" }}
          onChange={(e) => {
            filterAnswersByRubric(e.target.value as string);
          }}
          value={paramsFilterTable.rubric}
          style={{ width: "100%" }}
        >
          <MenuItem value={"0"} key={"O"} id={`item--FILTER--0`}>
            <em>Filtrar por Rubrica</em>
          </MenuItem>
          {rubrics.map((rubric: any, index: number) => (
            <MenuItem
              value={rubric.id}
              key={rubric.id}
              id={`item--${rubric.name}`}
            >
              ({rubric.id}) {rubric.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
    );
  };

  const evaluatedFilter = () => {
    return (
      <Box>
        <Select
          ref={openSelect}
          defaultValue={"0"}
          name="evaluated"
          sx={{ width: "300px" }}
          onChange={(e) => {
            filterByEvaluatedFlag(e.target.value as string);
          }}
          value={paramsFilterTable.evaluated}
          style={{ width: "100%" }}
        >
          <MenuItem value={"0"} key={"O"} id={`item--FILTER--0`}>
            <em>Filtrar por status de correção</em>
          </MenuItem>
          <MenuItem value={"true"} key={"1"} id={`item--FILTER--1`}>
            Corrigido
          </MenuItem>
          <MenuItem value={"false"} key={"2"} id={`item--FILTER--2`}>
            Aguardando correção
          </MenuItem>
        </Select>
      </Box>
    )
  }

  return (
    <div className={classes.root}>

      <div style={{ width: "100%" }}>
        <TitlePage
          title={taskState.title}
          goBackTo={
            "/admin/assessments/" +
            idAssessment +
            "/exams/" +
            examId +
            "/tasks/" +
            idTask +
            "/"
          }


        // stateRouteData={{
        //   id: taskState.taskState.id,
        //   title: taskState.taskState.title,
        //   content: taskState.taskState.content,
        //   items: taskState.taskState.items,
        //   examState: { ...taskState.taskState.examState },
        //   assessmentSelectedTeacher: {
        //     ...taskState.taskState.assessmentSelectedTeacher,
        //   },
        // }}
        />

        <Accordion
          sx={{ width: "100%" }}
          expanded={openDialogViewQuestion === true}
        >
          <AccordionSummary sx={{ width: "100%" }}>
            <Box
              sx={{ width: "100%" }}
              className={classes.area_info_correction}
            >
              <span className={classes.title}>Correção da Pergunta</span>

              <span className={classes.dividers}>|</span>

              <span className={classes.title}>
                Titulo:
                <span className={classes.subtitle}>{item?.title}</span>
              </span>

              <span className={classes.dividers}>|</span>

              <div className={classes.area_buttons_correction}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleAcoordionQuestionClick()}
                  startIcon={<VisibilityIcon />}
                  size="small"
                >
                  Visualizar Enunciado
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setOpenDialogViewAssessment(true)}
                  startIcon={<VisibilityIcon />}
                  size="small"
                >
                  Visualizar Avaliação
                </Button>
              </div>
              {/* <Typography className={classes.second_subtitle}>Pergunta:</Typography>
          <Typography className={classes.subtitle}>{itemContent}</Typography> */}
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box className={classes.dialog_content_details_item_correction}>
              <MarkdownConvert textInMarkdown={taskState} />

              <Box className={classes.area_details_item}>
                <Box>
                  <MarkdownConvert textInMarkdown={itemId} />
                </Box>

                <Box width="100%">
                  {/* {choices.length <= 0 ? (
                    <TextField
                      variant="outlined"
                      label="Resposta:"
                      fullWidth
                      multiline
                      minRows={3}
                      maxRows={3}
                      className="textarea_item_task"
                      placeholder="Digite sua resposta aqui..."
                      disabled
                    />
                  ) : (
                    <>
                      {choices?.map((op: any, i) => (
                        <Button key={i + 1} fullWidth>
                          <Avatar>{ALPHABET[i]}</Avatar>
                          <MarkdownConvert textInMarkdown={op.content} />
                        </Button>
                      ))}
                    </>
                  )} */}
                </Box>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
        <div style={{ float: "right" }}>{/* <Rubricas /> */}</div>
      </div>

      <div style={{ width: "100%" }}>
        <div style={{ float: "left" }}>
          <Typography
            className={classes.title}
            style={{ width: "100%" }}
          ></Typography>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          padding: "12px 24px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
      </div>




      <Grid container spacing={1}>
        <Grid item md={9} >
          <Searchbar
            elementsToFilter={answersEvaluation}
            adictionalFilters={[rubricFilter(), evaluatedFilter()]}
            setFilterResultFunction={setAnswersEvaluation}
            filterField=""
            specificFilterFunction={filterAnswersByAnswerText}
            titleTable="Listagem de Respostas"
            handleCleanSearh={handleCleanSearh}
            filterVisible={false}
          />
        </Grid>



        {answersEvaluation.length > 0 ? (
          <>
            <Grid item md={3} >
              <Grid container>
                <Grid item xs={6} md={6}>
                  <Button
                    onClick={() => { sendAll() }}
                    disabled={isButtonEnabled}
                    className={classes.button}
                    style={{
                      boxShadow: "0px 0px",
                      width: "80%"
                    }}
                    variant="contained"
                  >
                    Enviar
                  </Button>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Button
                    onClick={() => {handleResultView()}}
                    disabled={buttonResult}
                    variant="contained"
                    className={classes.button}
                    style={{
                      boxShadow: "0px 0px",
                      width: "80%"
                    }}
                  >
                    Resultados
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <TableContainer sx={{ width: "100%" }}>
              <Table stickyHeader aria-label="sticky table" style={{ padding: '0 0.8rem' }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">
                      <Button
                        variant="text"
                        endIcon={
                          paramsFilterTable.ordering === "-answer" ? (
                            <ExpandLessIcon
                              onClick={() => orderingAnswers("answer")}
                            />
                          ) : (
                            <ExpandMoreIcon
                              onClick={() => orderingAnswers("-answer")}
                            />
                          )
                        }
                      >
                        Respostas
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Button
                        variant="text"
                        endIcon={
                          paramsFilterTable.ordering === "-evaluation__rubric" ? (
                            <ExpandLessIcon
                              onClick={() => orderingAnswers("evaluation__rubric")}
                            />
                          ) : (
                            <ExpandMoreIcon
                              onClick={() => orderingAnswers("-evaluation__rubric")}
                            />
                          )
                        }
                      >
                        Correção
                      </Button>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pending ? (
                    <StyledTableRow>
                      <StyledTableCell
                        align="center"
                        colSpan={4}
                        style={{ height: "60vh" }}
                      >
                        <CircularProgress />
                      </StyledTableCell>
                    </StyledTableRow>
                  ) : (
                    answersEvaluation.map((answer, vetorIndex) => (
                      <StyledTableRow key={answer.answer_id}>
                        <StyledTableCell align="left">
                          <Box sx={{ marginLeft: "23px" }}>
                            {answer.answer}
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                          <FormControl sx={{ m: 1, minWidth: 120, width: 550 }}>
                            <Select
                              ref={openSelect}
                              className={classes.select}
                              defaultValue={answer ? answer.human_evaluaton_centroid : ""}
                              name="rubric"
                              value={answer.human_evaluaton_centroid}
                            >
                              {rubrics.map((rubric, index) => (
                                <MenuItem
                                  value={rubric.name}
                                  key={rubric.id}
                                  id={`item--${vetorIndex}`}
                                  onClick={() => {
                                    handleChangeMultipleChoices(
                                      {
                                        target: {
                                          name: "rubric",
                                          value: rubric.name,
                                        },
                                      },
                                      answer
                                    );
                                  }}
                                >
                                  ({rubric.id}) {rubric.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <Grid item xs={12}>
            <Box sx={{ padding: '16px', textAlign: 'center' }}>
              <b>Não há centroids para este item !</b>
            </Box>
          </Grid>
        )}

        <PrintAvaluation
          open={openDialogViewAssessment}
          setOpen={() => setOpenDialogViewAssessment(false)}
          idAssessment={idAssessment}
        />
      </Grid>

    </div >
  );
}
