import { useEffect, useState, useRef } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  Avatar,
  Box,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  IconButton,
  Stack,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { withStyles } from "@material-ui/core";

import Searchbar from "components/Searchbar/Searchbar";
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import SendIcon from "@mui/icons-material/Send";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import { MarkdownConvert } from "components/MarkdownConvert";

import StudentAnswersServices from "store/reducers/Admin/AsyncActions/studentsAnswers/studentanswers.service";
import RubricServices from "./../../../store/reducers/Admin/AsyncActions/rubric/rubric.service";
import StudentAnswersEvaluationServices from "store/reducers/Admin/AsyncActions/studentAnswersEvaluation/studentanswersevaluation.service";
import AuthenticationServices from "store/reducers/Authentication/AsyncActions/authentication.services";

import {
  StyledTableCell,
  StyledTableRow,
  useStyles,
} from "./styles/ItemCorrectionStyle";

import { IRegisterProps } from "store/reducers/Authentication/AsyncActions/types";
import { IStudantAnswer } from "store/reducers/Admin/Actions/StudantAnswerEvaluation copy/Actions/types";
import TitlePage from "components/Title/Title";
import { useHistory, useLocation, useParams } from "react-router-dom";
import React from "react";
import { PrintAvaluation } from "components/PrintAvaluation";
import StateBread from "utils/stateBreadcrumb/stateBreadcrumb";
import ItensServices from "store/reducers/Admin/AsyncActions/Itens/itens.service";
import TaskServices from "store/reducers/Admin/AsyncActions/Tasks/task.service";

export const ALPHABET = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];


var DEFAULT_EVALUATION = {
  id: 0,
  evaluator: 1,
  created_at: "",
  updated_at: "",
  rubric: 1,
  student_answer: 1,
  feedback: ""
}

export interface IRubric {
  id: number;
  name: string;
  order: number;
}

export interface IEvaluationForm {
  rubric: string;
  feedback: string;
}

export interface IStudantAnswerList {
  status: boolean;
  studantAnswer: IStudantAnswer;
  rubric: string;
  feedback: string;
}

export default function ItemCorrection(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openDialogViewQuestion, setOpenDialogViewQuestion] = useState(false);
  const [openDialogViewAssessment, setOpenDialogViewAssessment] = useState(false);

  const taskTitle = props.location.state?.taskState.title;
  const taskContent = props.location.state?.taskState.content;
  const [statusFilter, setStatusFilter] = useState<string>("")
  const [rubrics, setRubrics] = useState<any[]>([]);
  const [rubricsSelected, setRubricsSelected] = useState('');
  const [userContext, setUserContext] = useState<IRegisterProps>();
  const [answersEvaluation, setAnswersEvaluation] = useState<
    IStudantAnswerList[]
  >([]);
  const [answersEvaluationDeleteSelected, setAnswersEvaluationDeleteSelected] = useState<
    any
  >(null);
  const [answersEvaluationFiltered, setAnswersEvaluationFiltered] = useState<
    IStudantAnswerList[]
  >([]);
  const [count, setCount] = useState<any>(0);
  const [answers, setAnswers] = useState<IStudantAnswer[]>([]);
  const [answersMeta, setAnswersMeta] = useState<any>({
    count: null,
    next: null,
    previous: null,
  });
  const [pending, setPending] = useState<boolean>(false);

  const [searchText, setSearchText] = useState<string | null>(null);
  const [item, setItem] = useState<any>(null);
  const [task, setTask] = useState<any>(null);




  const openSelect = useRef<any>(null);

  const { idAssessment, examId, idTask, idItem } = useParams<{
    idAssessment: string;
    examId: string;
    idTask: string;
    idItem: string;
  }>();



  const [handleButtonSendAll, setHandleButtonSendAll] = useState(true);


  const title = taskTitle
  const location = useLocation();
  const history = useHistory();
  StateBread({ path: location.pathname, title: title })

  const [paramsFilterTable, setParamsFilterTable] = useState<{
    answer: any,
    rubric: any,
    ordering: any,
    offset?: any
    evaluated?: any
  }>({
    answer: "",
    rubric: "0",
    ordering: "",
    offset: "",
    evaluated: "0",
  });



  useEffect(() => {
    getItem()
    getTask()
  }, [])


  useEffect(() => {

    filterAnswers(paramsFilterTable);
    getRubrics();
    getUserID();
  }, [item]);




  useEffect(() => {
    if (answers.length > 0) {
      getAnswersEvaluation();
    }

  }, [answers]);

  useEffect(() => {
    filterAnswers(paramsFilterTable);
    // getRubrics();
    getAnswersEvaluation();

  }, [count]);


  useEffect(() => {
    filterAnswers(paramsFilterTable);
  }, [statusFilter])



  const getItem = () => {
    ItensServices.getByNumberId(Number(idItem)).then(({ data }: any) => {
      setItem(data);
    });
  };

  const getTask = () => {
    TaskServices.getById(Number(idTask)).then(({ data }: any) => {
      setTask(data);
    });
  };




  const deleteAnswerEvaluation = () => {
    StudentAnswersServices.deleteStudentAnswersEvaluation(answersEvaluationDeleteSelected.studantAnswer.evaluation.id).then(() => {
      setCount(count + 1)
      handleClose()
    }
    ).catch(() => {
      handleClose()
    })
  }

  const handlePageClusters = (item: any) => {
    history.push({
      pathname: `/admin/assessments/${idAssessment}/exams/${examId}/tasks/${idTask}/items/${idItem}/clusters/`,
      state: {
        item: item,
        itemId: item?.id,
        task,
      },
    });
  };

  const handleChangeAllChoices = async (event: any) => {
    const item = document.getElementById(`item--${event.target.value}`);

    const newState = await answersEvaluation.map((obj) => {
      if (obj.status !== true) {
        setHandleButtonSendAll(false)
        return { ...obj, rubric: event?.target?.value };
      } else {
        return obj;
      }
    });

    item?.click();
    item?.blur();
    console.table(newState);
    setAnswersEvaluation(newState);
  };

  const getAnswersEvaluation = async () => {
    if (rubrics.length > 0) {
      const newState = answers.map((itemEva) => {
        if (itemEva.evaluation != null) {
          return {
            studantAnswer: itemEva,
            status: true,
            rubric: rubrics[itemEva.evaluation.rubric - 1]["name"],
            feedback: itemEva.evaluation.feedback,
          };
        }
        return {
          studantAnswer: itemEva,
          status: false,
          rubric: "0",
          feedback: "",
        };
      });
      setAnswersEvaluation(newState);
    }
  };

  const filterAnswersByAnswerText = (answerText: string) => {
    const newParamsFilterTable = { ...paramsFilterTable, offset: "", answer: answerText };

    setParamsFilterTable(newParamsFilterTable);
    setStatusFilter("");

    if (answerText.trim().length > 0) filterAnswers(newParamsFilterTable);
  };

  const filterByEvaluatedFlag = (valueFilter: string) => {

    var newParamsFilterTable = { ...paramsFilterTable, evaluated: "" };

    if (valueFilter === 'true') {
      newParamsFilterTable = { ...paramsFilterTable, evaluated: "true" };
    } else if (valueFilter === 'false') {
      newParamsFilterTable = { ...paramsFilterTable, evaluated: "false" };
    }

    setParamsFilterTable(newParamsFilterTable);
    filterAnswers(newParamsFilterTable);

  }

  const filterAnswersByRubric = (rubric: string) => {


    const newParamsFilterTable = { ...paramsFilterTable, rubric };

    setParamsFilterTable(newParamsFilterTable);

    filterAnswers(newParamsFilterTable);
  };

  const orderingAnswers = (orderingBy: string) => {
    const newParamsFilterTable = {
      ...paramsFilterTable,
      ordering: orderingBy === paramsFilterTable.ordering ? "" : orderingBy,
    };

    setParamsFilterTable(newParamsFilterTable);

    filterAnswers(newParamsFilterTable);
  };

  const handleCleanSearh = () => {
    const newParamsFilterTable = { answer: "", rubric: "0", ordering: "", evaluated: "0" };
    setParamsFilterTable(newParamsFilterTable);
    filterAnswers(newParamsFilterTable);
  };

  function extractOffsetValue(urlString) {
    const regex = /offset=(\d+)/;
    const match = urlString.match(regex);

    if (match && match[1]) {
      return match[1];
    } else {
      return "";
    }
  }

  const filterAnswers = async (params: {
    answer: string;
    rubric: string;
    ordering: string;
    evaluated?: string
  }) => {
    const param_fixed = {
      answer: params.answer,
      evaluation__rubric: params.rubric !== "0" ? params.rubric : "",
      ordering: params.ordering,
      evaluated: params.evaluated !== "0" ? params.evaluated : ""
    };

    setPending(true);
    StudentAnswersServices.filterAnswers(
      Number(idAssessment),
      Number(idItem),
      { ...param_fixed, offset: extractOffsetValue(statusFilter) },
    ).then(({ data }: any) => {
      setAnswers(data.results);

      setAnswersMeta({
        count: data.count,
        next: data.next,
        previous: data.previous,
      });
      setPending(false);

    });
  };

  const getUserID = () => {
    AuthenticationServices.getIdUser().then((result: any) => {
      setUserContext(result.data);
    });
  };

  const insertCorrectionAnswer = async (answer, index) => {
    const datas = answersEvaluation[index];
    var rubric_selected = rubrics.find((obj) => obj.name === datas.rubric);

    const data = {
      evaluator: userContext?.id,
      rubric: rubric_selected["id"],
      student_answer: datas.studantAnswer.id,
      feedback: datas.feedback,
    };

    setTimeout(() => {
      setCount(count + 1);
    }, 1000);

    StudentAnswersEvaluationServices.register(data)
      .then(() => {
        const newState = answersEvaluation.map((obj) => {
          if (obj === answer) {
            return { ...obj, status: !obj.status };
          }
          return obj;
        });
        setAnswersEvaluation(newState);
      })
      .catch((error: any) => {
        // alert("Erro ao tentar inserir os dados");
      });
  };

  const updateCorrectionAnswer = async (answer, index) => {
    const datas = answersEvaluation[index];

    var rubric_selected = rubrics.find((obj) => obj.name === datas.rubric);
    const data = {
      evaluator: userContext?.id,
      rubric: rubric_selected["id"],
      student_answer: datas.studantAnswer.id,
      feedback: datas.feedback,
    };

    StudentAnswersEvaluationServices.update(
      datas.studantAnswer.evaluation.id,
      data
    )
      .then(() => {
        const newState = answersEvaluation.map((obj) => {
          if (obj === answer) {
            return { ...obj, status: !obj.status };
          }
          return obj;
        });
        setAnswersEvaluation(newState);
      })
      .catch((error: any) => {
        alert("Erro ao tentar alterar os dados");
      });
  };

  const getRubrics = async () => {
    RubricServices.findAll({ item: item?.id }).then(({ data }: any) => {
      if (data.results) {
        var sorted_rubrics = data.results.sort(function (a, b) {
          return a.id - b.id;
        });
      }

      setRubrics(sorted_rubrics);
    });
  };

  const handleButton = (answer: IStudantAnswerList, index) => {
    if (answer.status === false) {
      if (answer.studantAnswer.evaluation != null) {
        return (
          <>
            <Button
              style={{ marginRight: 4 }}
              color="error"
              variant="outlined"
              startIcon={<CancelIcon />}
              onClick={() => {
                enableEditButton(answer);
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="outlined"
              startIcon={<SendIcon />}
              onClick={() => {
                updateCorrectionAnswer(answer, index);
              }}
            >
              Atualizar
            </Button>
          </>
        );
      } else {
        return (
          <Button
            variant="contained"
            startIcon={<SendIcon />}
            onClick={() => {
              insertCorrectionAnswer(answer, index);
            }}
          >
            Enviar
          </Button>
        );
      }

    } else {
      return (
        <Stack direction="row" spacing={1}>
          <Button
            variant="outlined"
            startIcon={<EditIcon />}
            onClick={() => {
              enableEditButton(answer);
            }}
          >
            Editar
          </Button>

          <IconButton aria-label="delete" size="large" color="error">
            <DeleteIcon fontSize="inherit"
              onClick={() => {
                setAnswersEvaluationDeleteSelected(answer)
                handleClickOpen()
              }} />
          </IconButton>

          {/* <IconButton color="error" endIcon={<DeleteIcon />}
            
            style={{ maxWidth: "36px", minWidth: "36px", padding: 0 }}
          >
          </IconButton> */}
        </Stack>
      );
    }
  };



  const handleAcoordionQuestionClick = () => {
    setOpenDialogViewQuestion(!openDialogViewQuestion);
    // console.log(openDialogViewQuestion);
  };

  /**
   *
   * @param event objeto com target { name, value } com dados do item para resposta
   * @param answer objeto de resposta do aluno, passado por padrão na versão anterior, apontada acima
   */
  const handleChangeMultipleChoices = async (event, answer: IStudantAnswerList) => {

    const newState = answersEvaluation.map((obj) => {
      if (obj === answer) {
        return { ...obj, [event.target.name]: event.target.value };
      }
      return obj;
    });
    // console.clear();
    const item = document.getElementById(`item--${event.target.value}`);
    item?.click();
    item?.blur();
    console.table(newState);
    setAnswersEvaluation(newState);
  };


  const sendAll = async () => {
    const rubricSelected = rubrics.find(o => o.name === rubricsSelected);

    const filteredAnswers = await answersEvaluation.filter(obj => !obj.status);

    if (filteredAnswers.length > 0) {
      const studentAnswerIds = filteredAnswers.map(obj => obj.studantAnswer.id);
      const data = {
        student_answer_ids: studentAnswerIds,
        rubric_id: rubricSelected.id
      };
      StudentAnswersEvaluationServices.manyRegister(data)
        .then(() => {

          const updatedAnswers = answersEvaluation.map(obj => {
            if (studentAnswerIds.includes(obj.studantAnswer.id)) {
              return { ...obj, status: !obj.status };
            }
            return obj;
          });
          setAnswersEvaluation(updatedAnswers);
          setHandleButtonSendAll(true)

          setCount(count + 1);
        })
        .catch(error => {
          alert("Erro ao tentar inserir os dados");
        });
    } else {
      alert("Não há respostas para serem enviadas");
    }
  }

  const enableEditButton = async (answer) => {


    const teste = rubrics[answer?.studantAnswer?.evaluation?.rubric - 1]["name"]

    const newState = await answersEvaluation.map((obj) => {
      if (obj === answer) {
        return { ...obj, status: !obj.status, rubric: teste };
      }
      return obj;
    });

    setAnswersEvaluation(newState);

  };

  const rubricFilter = () => {
    return (
      <Box>
        <Select
          ref={openSelect}
          defaultValue={"0"}
          name="rubric"
          sx={{ width: "300px" }}
          onChange={(e) => {
            filterAnswersByRubric(e.target.value as string);
          }}
          value={paramsFilterTable.rubric}
          style={{ width: "100%" }}
        >
          <MenuItem value={"0"} key={"O"} id={`item--FILTER--0`}>
            <em>Filtrar por Rubrica</em>
          </MenuItem>
          {rubrics.map((rubric: any, index: number) => (
            <MenuItem
              value={rubric.id}
              key={rubric.id}
              id={`item--${rubric.name}`}
            >
              ({rubric.id}) {rubric.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
    );
  };

  const evaluatedFilter = () => {
    return (
      <Box>
        <Select
          ref={openSelect}
          defaultValue={"0"}
          name="evaluated"
          sx={{ width: "300px" }}
          onChange={(e) => {
            filterByEvaluatedFlag(e.target.value as string);
          }}
          value={paramsFilterTable.evaluated}
          style={{ width: "100%" }}
        >
          <MenuItem value={"0"} key={"O"} id={`item--FILTER--0`}>
            <em>Filtrar por status de correção</em>
          </MenuItem>
          <MenuItem value={"true"} key={"1"} id={`item--FILTER--1`}>
            Corrigido
          </MenuItem>
          <MenuItem value={"false"} key={"2"} id={`item--FILTER--2`}>
            Aguardando correção
          </MenuItem>
        </Select>
      </Box>
    )
  }

  return (
    <div className="main">
      <div className="main-content">
        <div className={classes.root}>
          <div style={{ width: "100%" }}>
            <TitlePage
              title={taskTitle}
              goBackTo={
                "/admin/assessments/" +
                idAssessment +
                "/exams/" +
                examId +
                "/tasks/" +
                idTask +
                "/"
              }
            // stateRouteData={{
            //   id: task.id,
            //   title: task.title,
            //   content: task.content,
            //   items: item,
            //   examState: { ...taskState.taskState.examState },
            //   assessmentSelectedTeacher: {
            //     ...taskState.taskState.assessmentSelectedTeacher,
            //   },
            // }}
            />

            <Accordion
              sx={{ width: "100%" }}
              expanded={openDialogViewQuestion === true}
            >
              <AccordionSummary sx={{ width: "100%" }}>
                <Box
                  sx={{ width: "100%" }}
                  className={classes.area_info_correction}
                >
                  <span className={classes.title}>Correção da Pergunta</span>

                  <span className={classes.dividers}>|</span>

                  <span className={classes.title}>
                    Titulo:
                    <span className={classes.subtitle}>{item?.title}</span>
                  </span>

                  <span className={classes.dividers}>|</span>

                  <div className={classes.area_buttons_correction}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleAcoordionQuestionClick()}
                      startIcon={<VisibilityIcon />}
                      size="small"
                    >
                      Visualizar Enunciado
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setOpenDialogViewAssessment(true)}
                      startIcon={<VisibilityIcon />}
                      size="small"
                    >
                      Visualizar Avaliação
                    </Button>

                    <span className={classes.dividers}>|</span>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handlePageClusters(item?.content)}
                      startIcon={<ScatterPlotIcon />}
                      size="small"
                    >
                      Clusters
                    </Button>


                  </div>
                  {/* <Typography className={classes.second_subtitle}>Pergunta:</Typography>
          <Typography className={classes.subtitle}>{itemContent}</Typography> */}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box className={classes.dialog_content_details_item_correction}>
                  <MarkdownConvert textInMarkdown={taskContent} />

                  <Box className={classes.area_details_item}>
                    <Box>
                      <MarkdownConvert textInMarkdown={item?.content} />
                    </Box>

                    <Box width="100%">
                      {item?.choices.length <= 0 ? (
                        <TextField
                          variant="outlined"
                          label="Resposta:"
                          fullWidth
                          multiline
                          minRows={3}
                          maxRows={3}
                          className="textarea_item_task"
                          placeholder="Digite sua resposta aqui..."
                          disabled
                        />
                      ) : (
                        <>
                          {item?.choices?.map((op: any, i) => (
                            <Button key={i + 1} fullWidth>
                              <Avatar>{ALPHABET[i]}</Avatar>
                              <MarkdownConvert textInMarkdown={op.content} />
                            </Button>
                          ))}
                        </>
                      )}
                    </Box>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
            <div style={{ float: "right" }}>{/* <Rubricas /> */}</div>
          </div>

          <div style={{ width: "100%" }}>
            <div style={{ float: "left" }}>
              <Typography
                className={classes.title}
                style={{ width: "100%" }}
              ></Typography>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              padding: "12px 24px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <pre>
          {JSON.stringify(answersMeta, null, 4)}
        </pre> */}
            <Button
              disabled={answersMeta && answersMeta.previous === null ? true : false}
              onClick={() => {
                // getAnswersByItem();
                setStatusFilter(answersMeta.previous ?? "")
              }}
              startIcon={<ArrowBack />}
            >
              Anterior
            </Button>
            <Button
              disabled={answersMeta && answersMeta.next === null ? true : false}
              onClick={() => {
                // getAnswersByItem(answersMeta.next ?? "");
                setStatusFilter(answersMeta.next ?? "")
              }}
              endIcon={<ArrowForward />}
            >
              Próximo
            </Button>
            {/* <pre>
          {JSON.stringify(answersMeta, null, 4)}
        </pre> */}
          </div>

          <Searchbar
            elementsToFilter={answersEvaluation}
            adictionalFilters={[rubricFilter(), evaluatedFilter()]}
            setFilterResultFunction={setAnswersEvaluation}
            filterField=""
            specificFilterFunction={filterAnswersByAnswerText}
            setTextSearch={setSearchText}
            titleTable="Listagem de Respostas"
            // numTotalRecordsTable={
            //   answersMeta.count + " registro(s) encontrado(s)"
            // }
            handleCleanSearh={handleCleanSearh}
            filterVisible={true}
          />


          <Grid container spacing={2} style={{ marginLeft: 0, display: "flex", justifyContent: "center", alignItems: "center" }} >
            <Grid item xs={6} md={6}>
              <FormControl fullWidth style={{ width: "100%" }}>
                <Select
                  name="rubric-selected"
                  defaultValue={"Selecione uma opção"}
                  onChange={(e) => setRubricsSelected(e.target.value)}
                >
                  <MenuItem value="Selecione uma opção">
                    <em>Selecione uma opção</em>
                  </MenuItem>
                  {rubrics.map((rubric: any, index: number) => (
                    <MenuItem
                      value={rubric.name}
                      key={rubric.id}
                      id={`item--${rubric.name}`}
                    >
                      ({rubric.id}) {rubric.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>


            </Grid>
            <Grid item xs={6} md={6}>
              <div style={{ display: "flex", justifyContent: "flex-start", gap: 10, }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {

                    handleChangeAllChoices({
                      target: {
                        name: "rubric",
                        value: rubricsSelected,
                      },
                    })


                  }}
                >
                  Aplicar a todos
                </Button>

                <Button
                  disabled={handleButtonSendAll}
                  variant="contained"
                  onClick={() => sendAll()}
                  className={classes.button}
                >
                  Enviar
                </Button>
              </div>
            </Grid>
          </Grid>

          <TableContainer sx={{ width: "100%" }}>
            <Table stickyHeader aria-label="sticky table" style={{ padding: '0 0.8rem' }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">
                    <Button
                      variant="text"
                      endIcon={
                        paramsFilterTable.ordering === "-answer" ? (
                          <ExpandLessIcon
                            onClick={() => orderingAnswers("answer")}
                          />
                        ) : (
                          <ExpandMoreIcon
                            onClick={() => orderingAnswers("-answer")}
                          />
                        )
                      }
                    //onClick={() => orderingAnswers("answer")}
                    >
                      Respostas
                    </Button>
                  </StyledTableCell>

                  <StyledTableCell align="left">
                    <Button
                      variant="text"
                      endIcon={
                        paramsFilterTable.ordering === "-evaluation__rubric" ? (
                          <ExpandLessIcon
                            onClick={() => orderingAnswers("evaluation__rubric")}
                          />
                        ) : (
                          <ExpandMoreIcon
                            onClick={() => orderingAnswers("-evaluation__rubric")}
                          />
                        )
                      }
                    //onClick={() => orderingAnswers("rubric")}
                    >
                      Correção
                    </Button>
                  </StyledTableCell>

                  <StyledTableCell align="left">
                    <Button
                      variant="text"
                    >
                      Corrigir
                    </Button>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pending ? (
                  <StyledTableRow>
                    <StyledTableCell
                      align="center"
                      colSpan={4}
                      style={{
                        height: "60vh",
                      }}
                    >
                      <CircularProgress />
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  <>

                    {answersEvaluation.length > 0 ?
                      answersEvaluation.map((answer, vetorIndex) => (
                        <StyledTableRow key={answer.studantAnswer.id}>
                          <StyledTableCell align="left">
                            <Box sx={{ marginLeft: "23px" }}>
                              {answer.studantAnswer.answer}
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell>
                            <FormControl sx={{ m: 1, minWidth: 120, width: 550 }}>
                              <Select
                                ref={openSelect}
                                disabled={answer.status}
                                className={classes.select}
                                defaultValue={
                                  answer.studantAnswer ? answer.rubric : ""
                                }
                                name="rubric"
                                value={answer.rubric}
                              >
                                {/* <MenuItem value="Selecione uma opção">
                            <em>Selecione uma opção</em>
                          </MenuItem> */}

                                {rubrics.map((rubric: any, index: number) => (
                                  <MenuItem
                                    value={rubric.name}
                                    key={rubric.id}
                                    id={`item--${vetorIndex}`}
                                    onClick={() => {
                                      handleChangeMultipleChoices(
                                        {
                                          target: {
                                            name: "rubric",
                                            value: rubric.name,
                                          },
                                        },
                                        answer
                                      );
                                    }}
                                    onKeyDown={(event) => {
                                      const validOptionsAux = rubrics.map((_) => {
                                        return JSON.stringify(_.id);
                                      });
                                      if (validOptionsAux.includes(event.key)) {
                                        /**
                                         * O bloco abaixo emite o evento modificado com os dados necessários
                                         * para fazer a modificação do estado.
                                         */
                                        handleChangeMultipleChoices(
                                          {
                                            target: {
                                              name: "rubric",
                                              value:
                                                rubrics[Number(event.key) - 1].name,
                                            },
                                          },
                                          answer
                                        );
                                      }
                                    }}
                                  >
                                    ({rubric.id}) {rubric.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </StyledTableCell>
                          <StyledTableCell>
                            {handleButton(answer, vetorIndex)}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))

                      :
                      <StyledTableRow>
                        <StyledTableCell align="center" colSpan={3}><b>Nenhum Resultado encontrado</b></StyledTableCell>
                      </StyledTableRow>
                    }
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div
            style={{
              marginTop: "24px",
              paddingTop: "24px",
              minHeight: "15vh",
            }}>
            <div
              style={{
                width: "100%",
                padding: "12px 24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <pre>
          {JSON.stringify(answersMeta, null, 4)}
        </pre> */}
              <Button
                disabled={answersMeta && answersMeta.previous === null ? true : false}
                onClick={() => {
                  // getAnswersByItem();
                  setStatusFilter(answersMeta.previous ?? "")
                }}
                startIcon={<ArrowBack />}
              >
                Anterior
              </Button>
              <Button
                disabled={answersMeta && answersMeta.next === null ? true : false}
                onClick={() => {
                  // getAnswersByItem(answersMeta.next ?? "");
                  setStatusFilter(answersMeta.next ?? "")
                }}
                endIcon={<ArrowForward />}
              >
                Próximo
              </Button>
              {/* <pre>
          {JSON.stringify(answersMeta, null, 4)}
        </pre> */}
            </div>
          </div>


          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Deseja remover a correção ? "}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Esta ação irá remover a correção de rubrica: "<b>{answersEvaluationDeleteSelected ? answersEvaluationDeleteSelected.rubric : ""}</b>" da resposta.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancelar</Button>
              <Button onClick={deleteAnswerEvaluation} autoFocus variant="contained" color="error">
                Remover
              </Button>
            </DialogActions>
          </Dialog>

          <PrintAvaluation
            open={openDialogViewAssessment}
            setOpen={() => setOpenDialogViewAssessment(false)}
            idAssessment={idAssessment}
          />
        </div>
      </div>
    </div>
  );
}
