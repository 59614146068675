import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AssessmentsServices from "store/reducers/Admin/AsyncActions/assessment/assessment.service";
import Searchbar from "components/Searchbar/Searchbar";
import { useHistory, useLocation } from "react-router-dom";
import "../../../routes/main.css"
import TableEmptyState from "components/TableEmptyState";
import StateBread from "utils/stateBreadcrumb/stateBreadcrumb";
import GenericTable from "components/Table";
import { Button } from "@mui/material";
import { access } from "fs";



interface IAssessmentItem {
  assessment: {
    id: number;
  };
  item: number;
}

const INITIAL_DATA_PAGINATION = {
  count: 0,
  next: "",
  previous: "",
  results: [],
};

export default function AssessmentsList() {
  const location = useLocation();
  const history = useHistory();
  const { user } = useSelector((state: any) => state.authentication);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [paramsPagination, setParamsPagination] = useState<string>("?limit=10")

  localStorage.setItem("breadcrumbs", "")
  const [assessment, setAssessement] = useState<any[]>([]);
  const [assessmentFiltered, setAssessementFiltered] = useState<any[]>([]);

  const [searchText, setSearchText] = useState<string>("");
  const columns = [
    { id: 'code', label: 'Código' },
    { id: 'title', label: 'Titulo' },
    { id: 'description', label: 'Descrição' },
    { id: 'starts_at', label: 'Inicio' },
    { id: 'ends_at', label: 'Fim' },
    { id: 'exams_length', label: 'Nº de Exames', format: (value) => value.length },
    { id: 'button', label: '' }
  ];


  StateBread({ path: location.pathname, title: "Avaliações" })

  useEffect(() => {
    getAssessments();
  }, [page]);

  const getAssessments = () => {
    if (user.type === "administrator") {
      AssessmentsServices.getAllAssessments(paramsPagination).then(({ data }: any) => {
        setTotalRows(data.count)
        setAssessement(data.results);
        setAssessementFiltered(data.results);
      });
    } else {
      AssessmentsServices.getAllAssessmentsByTeacher(user.id)
        .then(({ data }: any) => {
          let storageNewAssessment = [];

          let tempIdsAssessments = [];

          data.results.map((itemResult: IAssessmentItem) => {
            if (!tempIdsAssessments.includes(itemResult.assessment.id as never)) {
              storageNewAssessment = [...storageNewAssessment, { ...itemResult, item: [itemResult.item] }] as never;
              tempIdsAssessments = [...tempIdsAssessments, itemResult?.assessment?.id] as never;
            } else {
              storageNewAssessment = storageNewAssessment.map((itemAssessment: any) => {
                if (itemAssessment.assessment.id === itemResult.assessment.id) {
                  return {
                    ...itemAssessment,
                    item: [...itemAssessment.item, itemResult.item],
                  }
                } else {
                  return { ...itemAssessment }
                }
              }) as never;
            }
          });

          setAssessement(storageNewAssessment);
          setAssessementFiltered(storageNewAssessment);
        });
    }
  };

  const handleClick = (id: number, code: string, title: string, description: string, exams: [], assessmentSelectedTeacher?: {}) => {
    history.push({
      pathname: `/admin/assessments/${id}/`,
      state: {
        id: id,
        code,
        title,
        description: description,
        exams: exams,
        assessmentSelectedTeacher,
      },
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(event);
    setParamsPagination(`?limit=10&offset=${event*10-10}`)    
  };

  const buttonDetail = (assess) => {
    const getAssess = user.type === 'teacher' ? assess.assessment : assess;
    return (
      <Button variant="outlined" endIcon={<ArrowForwardIcon />}
        onClick={(event: any) =>
          handleClick(getAssess.id, getAssess.code, getAssess.title, getAssess.description, getAssess.exams, assess)
        }>
        Visualizar
      </Button>
    )
  }


  const formattedData = assessmentFiltered.map(assess => ({

    ...assess,
    starts_at: new Date(assess.starts_at).toLocaleDateString(),
    ends_at: new Date(assess.ends_at).toLocaleDateString(),
    exams_length: assess.exams.length,
    button: buttonDetail(assess)

  }));

  return (
    <div className="main">
      <div className="main-content">
        <Searchbar
          elementsToFilter={assessment}
          setFilterResultFunction={setAssessementFiltered}
          setTextSearch={setSearchText}
          filterField={"title"}
          specificFilterFunction={undefined}
          titleTable="Avaliações"
          filterVisible={true}
        />


        {assessmentFiltered.length > 0 ?
          <div>            
            <GenericTable
              columns={columns}
              data={formattedData}
              pagination={{
                numberActualPage: page,
                totalRecords: totalRows,
                handleChangePage: handleChangeRowsPerPage

              }}
            />
          </div>
          : <div>
            <TableEmptyState text={searchText} />
          </div>
        }
      </div>
    </div>

  );
}
