import * as React from 'react';
import Accordion, { AccordionSlots } from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fade from '@mui/material/Fade';
import { Divider, makeStyles, Typography } from '@mui/material';


export default function AccordionTransition(props) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <div style={{padding: "10px", marginBottom: "20px"}}>
      <Accordion
        expanded={expanded}
        onChange={handleExpansion}
        slots={{ transition: Fade as AccordionSlots['transition'] }}
        slotProps={{ transition: { timeout: 400 } }}
        sx={{
          '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
          '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
        }}

        style={{ border: "1px solid #CACDD5", backgroundColor: "#F7F8FA", }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          style={{
            padding: "0 0 0 10px", flexDirection: 'row-reverse',
            justifyContent: 'space-between',
            alignItems: 'center',            
          }}
        >
          <Typography style={{ fontSize: "16px", lineHeight: "21px", fontWeight: 600, color: "#0F1113", marginLeft: "10px" }}>Enuciado</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider style={{marginBottom: "20px"}}/>
          <Typography style={{padding: "20px"}}>
            {props?.text}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}