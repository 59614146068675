import { createAction } from "@reduxjs/toolkit";

export const setAssessmentReducer = createAction<any>('SET_ASSESSMENT');
export const setTaskReducer = createAction<any>('SET_TASK');
export const setExamReducer = createAction<any>('SET_EXAM');
export const setItemReducer = createAction<any>('SET_ITEM');

export const setAssessmentList = createAction<any>('SET_ASSESSMENT_LIST');
export const setTaskList = createAction<any>('SET_TASK_LIST');
export const setExamList = createAction<any>('SET_EXAM_LIST');
export const setItemList = createAction<any>('SET_ITEM_LIST');

export const clearValues = createAction<any>('CLEAR_VALUES');