import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, TextField } from "@mui/material";

import "./ChangePasswordForm.scss";
import AuthenticationServices, {
  ChangePasswordErrorResponse,
} from "store/reducers/Authentication/AsyncActions/authentication.services";
import { FC } from "react";
import { toast } from "react-toastify";

const scheme = z
  .object({
    oldPassword: z.string().min(1, { message: "Campo de senha é obrigatório" }),
    newPassword: z
      .string()
      .min(8, { message: "A senha deve ter no mínimo 8 caracteres" }),
    confirmPassword: z.string(),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: "A confirmação deve ser igual a nova senha",
    path: ["confirmPassword"],
  });

type ChangePassword = z.infer<typeof scheme>;

interface Props {
  onSubmitForm: () => void;
}

const ChangePasswordForm: FC<Props> = ({ onSubmitForm }) => {
  async function handleChange({ newPassword, oldPassword }: ChangePassword) {
    const response = AuthenticationServices.changePassword({
      current_password: oldPassword,
      new_password: newPassword,
    })
      .then(() => {
        onSubmitForm();
        toast.success("Senha alterada com sucesso");
      })
      .catch(() => {
        toast.error("Erro ao alterar senha, verifique os dados informados");
      });
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ChangePassword>({
    resolver: zodResolver(scheme),
  });

  return (
    <form
      id="changePasswordForm"
      style={{ background: "white" }}
      onSubmit={handleSubmit(handleChange)}
    >
      <TextField
        {...register("oldPassword")}
        label="Senha atual"
        type="password"
        error={!!errors.oldPassword?.message}
        helperText={(errors.oldPassword?.message as string) || ""}
      />
      <TextField
        {...register("newPassword")}
        label="Nova senha"
        type="password"
        error={!!errors.newPassword?.message}
        helperText={(errors.newPassword?.message as string) || ""}
      />
      <TextField
        {...register("confirmPassword")}
        label="Confirmação"
        type="password"
        error={!!errors.confirmPassword?.message}
        helperText={(errors.confirmPassword?.message as string) || ""}
      />

      <Button type="submit" variant="contained">
        Salvar
      </Button>
    </form>
  );
};

export default ChangePasswordForm;
