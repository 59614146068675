import api from "services/api";

export default class RubricServices {


	public static async register(props: any) {
		return api.post('/assessments/rubrics/', JSON.stringify(props), { headers: { 'Content-Type': 'application/json', } });
	}


	public static async deleteRibric(props: any) {
		return api.delete("/assessments/rubrics/" + props.id + "/");
	}

	public static async findAll(params: any) {
		return api.get('/assessments/rubrics/', { params });
	}
}
