import { createReducer } from '@reduxjs/toolkit';

import {
    login,
    logout,
} from './Actions';

const INITIAL_VALUES = {
    user: {
        id: 0,
        signed: false,
        token: '',
        id_user: 0,
        type: "",
        first_name: "",
        last_name: "",
    }
}

export default createReducer(INITIAL_VALUES, {
    [login.type]: (state, action) => ({ ...state, ...action.payload }),
    [logout.type]: () => ({ ...INITIAL_VALUES }),
});