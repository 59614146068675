import { Exam } from "services/admin/Interfaces/Types";
import api from "services/api";

export default class ExamServices {
	public static async registerExam(props: Exam) {
		return api.post("/assessments/exams/", JSON.stringify(props), {
			headers: { "Content-Type": "application/json" },
		});
	}

	public static async getAllExams() {
		return api.get("/assessments/exams/");
	}

	public static async updateExam(props: Exam) {
		return api.patch(
			"/assessments/exams/" + props.id + "/",
			JSON.stringify(props),
			{
				headers: { "Content-Type": "application/json" },
			}
		);
	}

	public static async deleteExam(props: Exam) {
		return api.delete("/assessments/exams/" + props.id + "/");
	}

	public static async getById(props: any) {		
		return api.get("/assessments/exams/" + props?.exam + "/");
	}
}
