import { Task } from "services/admin/Interfaces/Types";
import api from "services/api";

export default class TaskServices {
  public static async registerTask(props: Task) {
    const formData = new FormData();

    formData.append("title", props.title);
    formData.append("content", props.content);

    return api.post("/items/tasks/", formData);
  }

  public static async getAllTasks() {
    return api.get("/items/tasks/");
  }

  public static async deleteTask(props: Task) {
    return api.delete("/items/tasks/" + props.id + "/");
  }
  public static async getById(id: any) {
	return api.get("/items/tasks/" + id + "/");
 }

  public static async updateTask(props: Task) {
    const formData = new FormData();

    formData.append("title", props.title);
    formData.append("content", props.content);

    return api.put("/items/tasks/" + props.id + "/", formData);
  }
}
