import { ReactNode } from "react"

import { TableHeader, ITableHeader } from "./TableHeader"
import { Button, Pagination, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import './styles.css'

export interface ITbodyTr {
    tbodyTrTd: {
        element: string | ReactNode;
    }[]
}

const useStyles = makeStyles(() => ({
    root: {
        fontWeight: 600,
        fontSize: '14px',
        color: "#163447",
        overflowY: "auto",
        overflowX: "hidden",
    },
}));

const StyleTableCell = withStyles((theme) => ({
    root: {},
    body: {
        fontWeight: 600,
        fontSize: '14px',
        color: "#163447",
        overflowY: "auto",
        overflowX: "hidden",
    },
    head: {
        backgroundColor: "#F1F3F9",
        fontWeight: 600,
        lineHeight: "10px",
        fontSize: '14px',
        color: "var(--neutral-7)",
    },
}))(TableCell) as typeof TableCell;

interface ITable extends ITableHeader {
    tbodyTrs?: ITbodyTr[];
    pagination: {
        totalRecords: number;
        numberActualPage: number;
        rowsPerPage: number;
        handlePaginationChange: (event: unknown, page: number) => void
    }
}


const GenericTable = ({ columns, data, pagination }) => {



    const calculaPage = (value) => {
        if (value > 10) {
            return Math.ceil(value / 10);
        }
        return 1

    }

    const classes = useStyles();
    return (
        <TableContainer >
            <Table stickyHeader style={{ padding: '0 0.8rem' }}>
                <TableHead >
                    <TableRow style={{ height: "10px" }}>
                        {columns.map((column) => (
                            <StyleTableCell key={column.id} style={{ fontSize: "14px", fontWeight: 600, color: "#0F1113", backgroundColor: "#F1F3F9" }}>
                                {column.label}
                            </StyleTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                        <TableRow key={row.id}>
                            {columns.map((column) => (
                                <StyleTableCell key={column.id} style={{ fontSize: "14px", fontWeight: 400, lineHeight: "21px", color: "#0F1113" }}>
                                    {row[column.id]}
                                </StyleTableCell>
                            ))}
                            {/* <StyleTableCell>
                                <Button
                                    variant="outlined"
                                    endIcon={<ArrowForwardIcon />}
                                    onClick={() => handleButtonClick(row)}
                                >
                                    Visualizar
                                </Button>
                            </StyleTableCell> */}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                {/* <div className="" style={{ marginLeft: "10px" }}>1 a 10 de {pagination.totalRecords} registros</div> */}
                <div></div>
                <div className="" style={{ marginTop: "30px", paddingLeft: "10px", marginRight: "10px" }}>
                    <Stack >
                        <Pagination variant="outlined" shape="rounded" style={{ marginBottom: "32px" }}
                            count={calculaPage(pagination.totalRecords)}
                            page={pagination.numberActualPage}
                            onChange={(e, value) => pagination.handleChangePage(value)}
                        />
                    </Stack>
                </div>
            </div>

        </TableContainer>
    );
};

export default GenericTable;