import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import AppsIcon from '@mui/icons-material/Apps';

import {
  AppBar,
  Avatar,
  IconButton,
  Toolbar,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from "@material-ui/core/styles";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import CenpeLogo from "components/CenpeLogo";

import { login, setUserData } from "store/reducers/Authentication/Actions";

import { ILoginProps } from "store/reducers/Authentication/Actions/types";
import { IRegisterProps } from "store/reducers/Authentication/AsyncActions/types";
import { Grid, Menu, MenuItem } from "@mui/material";
import ChangePassword from "components/ChangePassword";
import { useAuth } from "@cenpe/auth";
import { MenuNavigationApps } from "components/MenuNavigationApps";

const useStyles = makeStyles(() => ({
  appbar: {
    backgroundColor: "var(--primary-dark)",
    boxShadow: "-2px 2px 10px rgba(9, 49, 62, 0.2)",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  boxElementsInLine: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "0.8rem",
  },
  title: {
    fontSize: "1.6rem",
    fontWeight: 600,
  },
  subtitle: {
    fontSize: "1.4rem",
  },
  iconExitApp: {
    color: "#f0f0f0",
    fontSize: "2rem",
  },
  menu: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-center",
    width: "478px",
    gap: "32px",
    fontSize: "16px",
  }
}));

interface IItemsOfNavigation {
  name: string;
  route: string;
  isSelected: boolean;
  isExclusive?: boolean;
}

interface INavbar {
  titleApp?: string;
  itemsOfNavigation?: IItemsOfNavigation[];
}

export default function Navbar({ titleApp = '', itemsOfNavigation = [] }: INavbar) {
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation()
  const dispath = useDispatch();
  const { user } = useSelector((state: any) => state.authentication);

  const [openChangePWDialog, setChangePWDialog] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { logout: handleLogout } = useAuth.getState();

  const [listItemsOfNavigation, setListItemsOfNavigation] = useState(itemsOfNavigation)


  function handleNavigation(route: string) {
    setListItemsOfNavigation(prevState => (prevState.map(itemPrevState => {
      const routeEquals = itemPrevState.route === route
      const routeContains = route.startsWith(itemPrevState.route)

      if (itemPrevState.isExclusive) {
        return routeEquals ?
          { ...itemPrevState, isSelected: true } :
          { ...itemPrevState, isSelected: false }
      } else {
        return routeContains ?
          { ...itemPrevState, isSelected: true } :
          { ...itemPrevState, isSelected: false }
      }
    })))
  }

  useEffect(() => handleNavigation(pathname), [pathname])


  const openMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function logout() {
    sessionStorage.clear();
    handleLogout(true);
  }

  function stringAvatar() {
    const firstLetter =
      user.first_name !== "" ? user.first_name?.split("")[0].toUpperCase() : "";
    const secondLetter = user.last_name
      ? user.last_name?.split("")[0].toUpperCase()
      : user.first_name?.split("")[1];

    return {
      style: {
        backgroundColor: "var(--primary-regular)",
      },
      children: <strong>{`${firstLetter}${secondLetter}`}</strong>,
    };
  }

  useEffect(() => {
    let appSession: any = sessionStorage.getItem("@App:S");
    let dataUserSession: any = sessionStorage.getItem("@App:U");

    if (appSession) {
      appSession = JSON.parse(window.atob(appSession));
      dispath(login({ ...appSession } as ILoginProps));
    }

    if (dataUserSession) {
      dataUserSession = JSON.parse(window.atob(dataUserSession));
      dispath(setUserData({ ...dataUserSession } as IRegisterProps));
    }
  }, [
    dispath,
    sessionStorage.getItem("@App:S"),
    sessionStorage.getItem("@App:U"),
  ]);

  if (!user.signed) {
    return <></>;
  }

  return (
    <AppBar position="relative" className={classes.appbar}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.boxElementsInLine}>
          <CenpeLogo height={40} unnamed />
        </div>

        <div className={classes.menu}>
          {
            listItemsOfNavigation.map(itemNavigation => (
              <MenuItem component={Link} to={itemNavigation.route} >
                {itemNavigation.name}
              </MenuItem>
            ))
          }


          {/* <MenuItem component={Link} to="/">
            Inicio
          </MenuItem>
          <MenuItem component={Link} to="/admin/assessments/">
            Avaliações
          </MenuItem>
          <MenuItem component={Link} to="/admin/transcriptions/">
            Validações
          </MenuItem> */}

        </div>
        

        <div className={classes.boxElementsInLine}>
          <MenuNavigationApps />

          <IconButton onClick={handleClick}>
            <Avatar {...stringAvatar()} />
            <Typography style={{ marginLeft: "15px", color: "white" }}>{user.first_name}</Typography>
            <ExpandMoreIcon style={{ color: "white" }}></ExpandMoreIcon>
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            onClick={handleClose}
          >
            <MenuItem
              onClick={() => {
                setChangePWDialog(true);
                handleClose();
              }}
            >
              Alterar Senha
            </MenuItem>

            <MenuItem
              onClick={logout}
            >
              Sair
            </MenuItem>
          </Menu>

          <ChangePassword
            isOpen={openChangePWDialog}
            onClose={() => setChangePWDialog(false)}
          />
        </div>
      </Toolbar>
    </AppBar >
  );
}
