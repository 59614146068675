import { TRANSCRIPTIONS_ROUTE } from "pages/Admin/sections/constants";


export const menuData = [
  {
    name: "Avaliações",
    path: "/admin/assessments/",
  },
  {
    name: "Validações",
    path: TRANSCRIPTIONS_ROUTE,
  },
];
