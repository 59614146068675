import { createReducer } from "@reduxjs/toolkit";
import { SelectedElement } from "services/admin/Interfaces/Types";
import { getAllAssessments } from "./Actions/Assessment/action";
import { getExams } from "./Actions/Exam/action";
import { getKnowledgesArea } from "./Actions/KnowledgeArea/action";
import { getAllAssessmentExam } from "./Actions/assessmentExam/action";

//import { createItem, removeItem, updateItem } from "./Actions/Itens/actions";
import { getAllTasks } from "./Actions/Tasks/actions";
import {
  resetClickedElements,
  setSelectedElement,
} from "./Actions/treeView/actions";

var emptySelectedElement: SelectedElement = {
  elementType: undefined,
  element: undefined,
  elementDataForm: undefined,
};

const INITIAL_VALUES = {
  tasks: [],
  assessments: [],
  exams: [],
  knowledgeAreas: [],
  assessmentsExam: [],
  selectedElement: emptySelectedElement as SelectedElement,
  schools: [],
};

export default createReducer(INITIAL_VALUES, {
  [getAllTasks.type]: (state, action) => {
    state.tasks = action.payload;
  },

  [getAllAssessments.type]: (state, action) => {
    state.assessments = action.payload;
  },

  [getAllAssessmentExam.type]: (state, action) => {
    state.assessmentsExam = action.payload;
  },

  [getExams.type]: (state, action) => {
    state.exams = action.payload;
  },

  [getKnowledgesArea.type]: (state, action) => {
    state.knowledgeAreas = action.payload;
  },

  [resetClickedElements.type]: (state) => {
    state.selectedElement = emptySelectedElement;
  },

  [setSelectedElement.type]: (state, action) => {
    state.selectedElement = action.payload;
  },
});
