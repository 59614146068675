import { ThemeProvider } from "@mui/material";

// import { theme } from "components/Admin/Themes";
import { theme } from "assets/themes/MainTheme";

import Content from "./sections/Content";

import "./admin.css";
import "./../../App.css"

export default function Admin() {
	return (
		<ThemeProvider theme={theme}>
			<div>
				<Content />
			</div>
		</ThemeProvider>
	);
}
