import { AddCircle } from "@mui/icons-material";
import {
  IconButton,
  LinearProgress,
  Pagination,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import {
  StyledTableCell,
  StyledTableRow,
} from "pages/Admin/Evaluator/styles/ItemCorrectionStyle";

import "./TableAnswers.scss";

export interface ITableAnswers {
  pending: boolean;
  answers: any[];
  handleClick: any;
  icon?: any;
  paginate?: {
    page: number;
    count: number;
    onChangePage: (page: number) => void;
  };
}

const TableAnswer = (props: ITableAnswers) => {
  const { pending, answers, handleClick, icon, paginate } = props;

  return (
    <TableContainer sx={{ width: "100%" }}>
      {(answers.length === 0 || pending) && (
        <div id="no-results-message-container">
          {pending ? (
            <div id="loading-message-container">
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
              <span>Carregando dados.</span>
            </div>
          ) : (
            <span>Nenhum registro encontrado.</span>
          )}
        </div>
      )}

      {answers.length > 0 && !pending && (
        <Table style={{ padding: "0 0.8rem" }}>
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">ID</StyledTableCell>
              <StyledTableCell align="left">Respostas</StyledTableCell>
              <StyledTableCell align="left"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {answers.map((answer) => (
              <StyledTableRow key={answer.id}>
                <StyledTableCell align="left">{answer.id}</StyledTableCell>
                <StyledTableCell>
                  <Box sx={{ marginLeft: "23px" }}>{answer.pre_answer}</Box>
                </StyledTableCell>
                <StyledTableCell>
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleClick(answer.id)}
                  >
                    {icon ? (
                      icon
                    ) : (
                      <Tooltip title="Adicionar Validação">
                        <AddCircle />
                      </Tooltip>
                    )}
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      )}

      {paginate && answers.length > 0 && !pending && (
        <div id="pagination-data-container">
          <Pagination
            count={paginate.count}
            page={paginate.page}
            
            onChange={(_, pageValue) => {
              paginate.onChangePage(pageValue);
            }}
          />
        </div>
      )}
    </TableContainer>
  );
};

export default TableAnswer;
