import { Typography, CircularProgress } from "@mui/material"

import './styles.css'

export function LoadingPage() {
    return (
        <div className="content_loading">
            <div className="area_loading">
                <Typography className="text_loading">Por favor, aguarde...</Typography>
                <CircularProgress />
            </div>
        </div>
    )
}