import { Item } from "services/admin/Interfaces/Types";
import api from "services/api";

export default class ItensServices {
	public static async registerItem(props: Item) {
		return api.post('/items/items/', JSON.stringify(props), { headers: { 'Content-Type': 'application/json', } });
	}

	public static async updateItem(props: Item) {
		return api.patch('/items/items/' + props.id + "/", JSON.stringify(props), { headers: { 'Content-Type': 'application/json', } });
	}

	public static async deleteItem(props: Item) {
		return api.delete('/items/items/' + props.id + "/");
	}

	public static async getById(props: Item) {
		return api.get('/items/items/' + props.id + "/");
	}

	public static async getByNumberId(id: number) {
		return api.get('/items/items/' + id + "/");
	}

	public static async getAll(props: Item) {
		return api.get('/items/items/' + props.id + "/");
	}

	public static async findAll(params: any) {
		return api.get('/items/items/', { params });
	}

}