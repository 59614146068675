import { useLocation, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Box, Button, Divider, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import CustomBread from "components/Breadcrumbs/Bread";

const useStyles = makeStyles(() => ({
  areaButtonBread: {
    margin: "0.8rem 0",
    display: "flex",
    gap: "0.8rem",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0 1rem",
  },
  areaTitle: {
    margin: "0.8rem 0",
    display: "flex",
    gap: "0.8rem",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0 1rem",
    marginBottom: "32px"
  },
  iconGoBack: {
    color: "var(--primary-dark)",
    borderColor: "#CACDD5",
    width: "10px"
  },
  title: {
    fontFamily: "Poppins",
    fontSize: "2rem",
    fontWeight: 600,
  },
  subtitle: {
    fontFamily: "Poppins",
    color: "#1D2432",
    fontSize: "14px",
    marginTop: "20px"
  },
  description: {
    fontFamily: "Poppins",
    color: "#1D2432",
    fontSize: "14px",
    marginTop: "15px",
    width: "100%"
  },
}));

export default function TitlePage(props: any) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const title = props.title;
  const subtitle = props.subtitle;
  const description = props.description;
  const goBackTo = props.goBackTo ?? "/";
  const stateRouteData = props.stateRouteData ?? {};

  const current = localStorage.getItem('breadcrumbs')
  let listaDeObjetos: any = []
  function goBack() {
    history.push({ pathname: goBackTo, state: stateRouteData });    
    if (current) {      
      listaDeObjetos = JSON.parse(current);      
      listaDeObjetos.pop()
    }
  }



  function showGoBack() {
    return location.pathname !== "/";
  }


  return (
    <>
      <Box className={classes.areaButtonBread}>
        {showGoBack() && (
          <>
            <Button onClick={goBack} variant="outlined" size="small">
              <ArrowBackIcon className={classes.iconGoBack} />
            </Button>

            <CustomBread current={title} />
          </>

        )}

      </Box>

      <Box className={classes.areaTitle}>
        <Box>
          <Typography className={classes.title}>{title}</Typography>
          <Typography className={classes.subtitle}>{subtitle}</Typography>
          <Typography className={classes.description}>{description}</Typography>
        </Box>
      </Box>

      <div>
        {props?.container}
      </div>

      <Divider style={{ padding: "0 1rem", margin: "0 0.2rem 0 0.8rem", borderBlockColor: "#E7E9EE" }} />      
    </>

  );
}
