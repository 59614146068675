import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "./App.css";

import { ssoConfig } from "./ssoConfig";
import { setAuthConfig } from "@cenpe/auth";

setAuthConfig(ssoConfig);

ReactDOM.render(<App />, document.getElementById("root"));
