import { emphasize, withStyles, Theme } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Chip from '@material-ui/core/Chip';
import { Box, Link, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';







export default function CustomBread(props: any) {
	const history = useHistory();

	const [valueBread, setValueBread] = useState<any[]>([]);

	const current = localStorage.getItem('breadcrumbs')
	let listaDeObjetos: any = []

	if (current) {
		listaDeObjetos = JSON.parse(current);
		listaDeObjetos.pop()
	} else {
		listaDeObjetos = [];
	}

	function handleClick(path) {
		if (current) {
			let objects = JSON.parse(current);

			const index = objects.findIndex(obj => obj.path === path);

			if (index !== -1) {
				objects = objects.slice(0, index + 1);
			}

			localStorage.setItem('breadcrumbs', JSON.stringify(objects));

			history.push({
				pathname: path
			});

			return objects;
		}
		return [];
	}

	return (
		<Box>
			<Breadcrumbs aria-label="breadcrumb" style={{ fontSize: "12px", fontWeight: 600, lineHeight: "21px" }}>
				{listaDeObjetos.map((bread) => (
					<Link key={bread.path} style={{ color: "#365BDC" }} onClick={() => {
						handleClick(bread.path)
					}}>
						{bread.title}
					</Link>
				))}
				<Typography>{props.current}</Typography>
			</Breadcrumbs>
		</Box>
	);
}