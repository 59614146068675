import React from 'react';

// Importando as rotas 
import OuterRoutes from "./OuterRoutes";

import { useSelector } from 'react-redux';
import SignRoutes from './SignRouter';

const Routes: React.FC = () => {
    const { user } = useSelector((state: any) => state.authentication);
    return user.signed && user.type !== 'student' ? <OuterRoutes /> : <SignRoutes />
}
export default Routes; 