import logoCenpe from "../../assets/logos/logo-new.png";
import logoCenpeUnnamed from "../../assets/logos/logo-unnamed.png";
import { Typography } from "@mui/material";

interface Props {
  height?: string | number;
  unnamed?: boolean;
}

const CenpeLogo = ({ height = "auto", unnamed = false }: Props) => {
  return (
    <>
      <img
        src={logoCenpe}
        height={height}
        alt="Logo do CEnPE"
      />
      <Typography variant="h6" style={{paddingLeft: "5px"}}>
        Correção de Avaliação
      </Typography>
    </>
  );
};

export default CenpeLogo;
