import { createReducer } from "@reduxjs/toolkit";
import { Assessment } from "services/admin/Interfaces/Types";
import { resetTranscriberAssessment, getTranscriberAssessment } from "./Actions";


const INITIAL_STATE = {
    transcriberAssessment: [] as any[],
}

const transcriberAssessment = createReducer(

    INITIAL_STATE, {
        [resetTranscriberAssessment.type]: (state) => ({ ...state, series: [...INITIAL_STATE.transcriberAssessment] }),
        [getTranscriberAssessment.type]: (state, action) => {
            state.transcriberAssessment = action.payload;
        }
    }
);

export default transcriberAssessment;