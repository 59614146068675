import { useEffect, Fragment, useState } from 'react'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'

import CloseIcon from '@mui/icons-material/Close'

import { MarkdownConvert } from 'components/MarkdownConvert'
import { LoadingPage } from './LoadingPage'
import { CoverEvaluation } from './CoverEvaluation'
import { ItemEvaluation } from './ItemAvaluation'

import { orderArrayById } from 'utils/orderArrayById'

import api from 'services/api'

import './styles.css'

interface IAssessment {
    id: number;
    code: string;
    created_at: string;
    created_by: number;
    description: string;
    ends_at: string;
    exam_time: number;
    exams: {
        id: number;
        exam: number;
        assessment: number;
        title: string;
    }[];
    min_evaluators: number;
    min_transcribers: number;
    modified_at: string;
    starts_at: string;
    title: string;
}

interface IExam {
    id: number;
    code: string;
    created_at: string;
    created_by: number;
    description: string;
    knowledge_area: number;
    modified_at: string;
    published: boolean;
    tasks: {
        id: number;
        exam: number;
        number: number;
        task: number;
    }[];
    title: string;
}

interface IExamsForAssessment {
    id_assessment: number;
    id_exam: number;
    list_tasks: {
        id: number;
        title: string;
        content: string;
        created_at: string;
        created_by: number;
        items: {
            id: number;
            item_bank: number;
            number: number;
            task: number;
            created_by: string;
            title: string;
            content: string;
            created_at: string;
            choices: {
                id: number;
                item: number;
                content: string;
                correct: boolean;
                created_at: string;
            }[];
            abilities: {
                id: number;
                item: number;
                ability: number;
            }[];
        }[];
        subcontents: {
            content: string;
            id: number;
            position: number;
            task: number;
        }[]
    }[]
}

interface IPrintAvaluation {
    idAssessment: string;
    open: boolean;
    setOpen: () => void;
}

export function PrintAvaluation({ open, setOpen, idAssessment }: IPrintAvaluation) {
    let numberItem = 0

    const [storeIdAssessment, setStoreIdAssessment] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [assessment, setAssessment] = useState({ title_assessment: '', description_assessment: '' })
    const [examsForAssessment, setExamsFormAssessment] = useState<IExamsForAssessment[]>([])

    useEffect(() => {
        if (open && idAssessment) {
            if (idAssessment !== storeIdAssessment) {
                setIsLoading(true)

                api.get(`/assessments/assessments/${idAssessment}`)
                    .then(({ data }) => {
                        const assessmentData = data as IAssessment;

                        Promise.all(
                            orderArrayById(assessmentData.exams).map(exam => api.get(`/assessments/exams/${exam.exam}`))
                        )
                        .then(async (responsesExams) => {
                            let exams: any = [];
                            
                            for (const responseExam of responsesExams) {
                                const examData = responseExam.data as IExam;

                                const responseExamWithTasks = await Promise.all(orderArrayById(examData.tasks).map(task => api.get(`/items/tasks/${task.task}`)))
                                    .then((responsesTasks) => {
                                        return {
                                            id_assessment: assessmentData.id,
                                            id_exam: examData.id,
                                            list_tasks: responsesTasks.map(task => task.data)
                                        } as IExamsForAssessment
                                    })

                                exams.push(responseExamWithTasks)
                            }
                            
                            setAssessment({
                                title_assessment: assessmentData.title,
                                description_assessment: assessmentData.description
                            })

                            setExamsFormAssessment(exams)

                            setStoreIdAssessment(idAssessment)

                            setTimeout(() => {
                                setIsLoading(false)
                            }, 5000)
                        })
                    })
                    .catch(console.log)
            }
        }
        //eslint-disable-next-line
    }, [open, idAssessment])

    return (
        <Dialog
            open={open}
            onClose={isLoading ? () => {} : setOpen}
            fullScreen
        >
            <DialogTitle className="title_preview_assessment">
                <h3>Visualização da Avaliação</h3>

                <Button
                    onClick={isLoading ? () => {} : setOpen}
                    title="Fechar visualizar prova"
                    endIcon={<CloseIcon />}
                    color="error"
                >
                    Fechar
                </Button>
            </DialogTitle>

            <DialogContent>
                <div className="preview_evaluation" style={{ position: 'relative' }}>
                    {
                        isLoading && <LoadingPage />
                    }

                    <div className="print_assessment" id="page">
                        <CoverEvaluation {...assessment} />

                        {
                            examsForAssessment.map(reader_exam => (
                                <Fragment key={`exam-item-${reader_exam.id_exam}`}>
                                    {
                                        reader_exam.list_tasks.map(reader_task => {
                                            return (
                                                <Fragment key={`completed-task-${reader_task.id}`}>
                                                    <div id={`task-${reader_task.id}`} style={{ padding: '50pt' }}>
                                                        { reader_task.content && <MarkdownConvert textInMarkdown={reader_task.content} /> }
                                                    </div>
                        
                                                    {
                                                        reader_task.items.map((itemTask, index) => {
                                                            numberItem += 1

                                                            const selectSubContent = reader_task.subcontents.filter(subcontent => subcontent.position === index + 1)

                                                            return (
                                                                <>
                                                                    {
                                                                        selectSubContent.length > 0 ?
                                                                            <div id={`task-sub-content-${numberItem}`} style={{ padding: '0 50pt' }}>
                                                                                <MarkdownConvert textInMarkdown={selectSubContent[0].content} />
                                                                            </div>
                                                                        : <></>
                                                                    }

                                                                    <ItemEvaluation
                                                                        key={`item-${itemTask.id}`}
                                                                        item={itemTask}
                                                                        num_item={numberItem}
                                                                        id_exam={reader_exam.id_exam}
                                                                        id_assessment={reader_exam.id_assessment}
                                                                    />
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </Fragment>
                                            )
                                        })
                                    }
                                </Fragment>
                            ))
                        }
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}