import { Box, Grid } from "@mui/material";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import useDataUser from "services/hooks/useDataUser";
import { menuData } from "./constants";
import styles from "./styles.module.scss";
import { TRANSCRIPTIONS_ROUTE } from "pages/Admin/sections/constants";





const Home = () => {
  localStorage.setItem('breadcrumbs', "")

  const user = useDataUser();
  const history = useHistory();
  React.useEffect(() => {
    if (user?.type === "transcriber") history.push(TRANSCRIPTIONS_ROUTE);
  }, [user]);
  return (
    <div className={styles.home_root}>
      <div className={styles.leftPainel}>
        <div className={styles.cardButtons}>
          <Grid container>
            <Grid md={6}>
              <div style={{ textAlign: "center" }}>
                <h1 style={{ fontSize: "40px" }}>Correção de Avaliações</h1>
                <br></br>
                <h4 style={{ marginBottom: "20px" }}>Selecione uma opção:</h4>
                <br></br>
                {menuData.map((data) => (
                  <div style={{ marginBottom: "20px" }} key={data.name}>
                    <Link to={data.path} key={data.path}>
                      <button className={styles.Button}>{data.name}</button>
                    </Link>
                  </div>
                ))}
              </div>
            </Grid>
            <Grid md={6}>

            </Grid>
          </Grid>

        </div>


      </div>
    </div>

  );
};

export default Home;
