import React, { useEffect, useState } from "react";
import AssessmentsServices from "store/reducers/Admin/AsyncActions/assessment/assessment.service";
import TitlePage from "components/Title/Title";
import Searchbar from "components/Searchbar/Searchbar";
import ExamServices from "store/reducers/Admin/AsyncActions/Exam/exam.service";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button } from "@mui/material";
import TableEmptyState from "components/TableEmptyState";
import StateBread from "utils/stateBreadcrumb/stateBreadcrumb";
import GenericTable from "components/Table";


const columns = [
  { id: 'code', label: 'Código' },
  { id: 'description', label: 'Descrição' },
  { id: 'created_by', label: 'Criador' },
  { id: 'created_at', label: 'Criado' },
  { id: 'published', label: 'Publicado' },
  { id: 'button', label: '' }
];



export default function AssessmentsExams(props) {
  const history = useHistory();
  const { idAssessment } = useParams<{ idAssessment: string }>();
  const { user } = useSelector((state: any) => state.authentication);
  const [assessment, setAssessement] = useState<any>(null);
  const [exams, setExams] = useState<any[]>([]);
  const [examsFiltered, setExamsFiltered] = useState<any[]>([]);

  const descriptionAssess = props.location.state?.description;
  const codeAssess = props.location.state?.code;
  const titleAssess = props.location.state?.title;
  const assessmentExams = props.location.state?.exams;
  const assessmentSelectedTeacher = props.location.state?.assessmentSelectedTeacher;

  const [searchText, setSearchText] = useState<string | null>(null);

  const [title, setTitle] = useState<string>("Avaliação: " + titleAssess)
  const location = useLocation();

  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [paramsPagination, setParamsPagination] = useState<string>("?limit=10")

  StateBread({ path: location.pathname, title: title })

  const handleChangeRowsPerPage = (event) => {
    setPage(event);
    setParamsPagination(`?limit=10&offset=${event * 10 - 10}`)
  };

  const buttonDetail = (exam) => {
    return (

      <Button variant="outlined" endIcon={<ArrowForwardIcon />}
        onClick={(event: any) => handleClick(exam)}>
        Visualizar
      </Button>
    )
  }

  const formattedData = examsFiltered.map(exam => ({

    ...exam,
    created_at: new Date(exam.created_at).toLocaleDateString(),
    description: exam.description,
    published: exam.published ? "Sim" : "Não",
    button: buttonDetail(exam)

  }));


  useEffect(() => {
    getAssessment();
  }, []);

  useEffect(() => {
    getAllExams();
    setTitle("Avaliação: " + assessment?.title)
  }, [assessment])


  const getAllExams = () => {
    if (user.type === 'teacher') {
      setExams((exam) => [...exam, assessmentSelectedTeacher.exam]);
      setExamsFiltered((exam) => [...exam, assessmentSelectedTeacher.exam]);
    } else {
      assessment?.exams.forEach((aExam) => {
        ExamServices.getById(aExam)
          .then(({ data }: any) => {
            setExams((exam) => [...exam, data]);
            setExamsFiltered((exam) => [...exam, data]);
          })
          .catch((error) => {
            alert("Nenhum registro encontrado");
          });
      });
    }
  };

  const getAssessment = () => {
    AssessmentsServices.getAllAssessmentsById(Number(idAssessment)).then(({ data }: any) => {
      setAssessement(data);
      setExams(data.exams)
    });
  };


  const handleClick = (exam: any) => {
    history.push({
      pathname: `/admin/assessments/${idAssessment}/exams/${exam?.id}/`,
      state: {
        id: exam?.id,
        description: exam?.description,
        tasks: exam?.tasks,
        exams: assessmentExams,
        assessmentSelectedTeacher,
        code: codeAssess,
        title: titleAssess,
      },
    });
  };

  return (
    <div className="main">
      <div className="main-content">
        <TitlePage
          title={title}
          subtitle={`Código: ${assessment?.code}`}
          description={assessment?.description}
          goBackTo="/admin/assessments"
        />

        <Searchbar
          elementsToFilter={exams}
          setFilterResultFunction={setExamsFiltered}
          filterField={"description"}
          setTextSearch={setSearchText}
          specificFilterFunction={undefined}
          titleTable="Exames"
          filterVisible={true}
        />
        {/* <ActionButtonEvaluator disabledButton={statusButtons} /> */}
        {examsFiltered.length > 0 ?
          <div>
            <GenericTable
              columns={columns}
              data={formattedData}
              pagination={{
                numberActualPage: page,
                totalRecords: totalRows,
                handleChangePage: handleChangeRowsPerPage

              }}
            />
          </div>
          : <div>
            <TableEmptyState text={searchText} />
          </div>
        }
      </div>
    </div>
  );
}
