import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TitlePage from "components/Title/Title";
import Searchbar from "components/Searchbar/Searchbar";
import TaskServices from "store/reducers/Admin/AsyncActions/Tasks/task.service";
import { Button, Pagination, Stack } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TableEmptyState from "components/TableEmptyState";
import StateBread from "utils/stateBreadcrumb/stateBreadcrumb";
import CardItens from "components/CardItens";
import AssessmentsServices from "store/reducers/Admin/AsyncActions/assessment/assessment.service";

export default function ExamsList(props) {
  const { user } = useSelector((state: any) => state.authentication);

  const { idAssessment, examId } = useParams<{
    idAssessment: string;
    examId: string;
  }>();
  

  const { idExam } = useParams<{ idExam: string }>();
  const [tasks, setTasks] = useState<any[]>([]);
  const examState = props.location.state;
  const examDescription = props.location.state?.description;
  const assessmentSelectedTeacher =
    props.location.state?.assessmentSelectedTeacher;
  const [tasksFiltered, setTasksFiltered] = useState<any[]>([]);
  const [exams, setExams] = useState<any>(null);


  const [searchText, setSearchText] = useState<string | null>(null);


  const title = examDescription
  const location = useLocation();

  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [paramsPagination, setParamsPagination] = useState<string>("?limit=10")

  const handleChangeRowsPerPage = (event) => {
    setPage(event);
    setParamsPagination(`?limit=10&offset=${event * 10 - 10}`)
  };

  StateBread({ path: location.pathname, title: title })


  useEffect(() => {
    getExams();
  }, []);


  useEffect(() => {
    getTasks();
  }, [exams]);



  const getExams = () => {
    AssessmentsServices.getAllExamsById(Number(idExam)).then(({ data }: any) => {
      setExams(data);
    });
  };


  const truncateText = (text: String, maxSize: number): String => {
    if (text.length < maxSize) {
      return text;
    } else {
      return text.substring(0, maxSize) + " ...";
    }
  };

  const getTasks = () => {
    const getTasks =
      user.type === "teacher"
        ? assessmentSelectedTeacher.exam.tasks
        : exams?.tasks;

    if (exams?.tasks.length > 0) {
      exams?.tasks.forEach((eTask) => {
        TaskServices.getById(eTask?.task)
          .then(({ data }: any) => {
            if (user.type === "teacher") {
              let insertTaskInList = false;

              for (let i = 0; i < assessmentSelectedTeacher.item.length; i++) {
                const countItemsByTeacherInTask = data.items.filter(
                  (itemTask) =>
                    itemTask.id === assessmentSelectedTeacher.item[i]
                );

                if (countItemsByTeacherInTask.length > 0) {
                  insertTaskInList = true;
                  break;
                }
              }

              if (insertTaskInList) {
                setTasks((t) => [...t, data]);
                setTasksFiltered((t) => [...t, data]);
              }
            } else {
              setTasks((t) => [...t, data]);
              setTasksFiltered((t) => [...t, data]);
            }
          })
          .catch(() => {
            console.log("Nenhum registro encontrado!");
          });
      });
    }
  };

  const handleClick = (event: React.MouseEvent<unknown>, task: any) => {
    props.history.push({
      pathname: `/admin/assessments/${idAssessment}/exams/${idExam}/tasks/${task?.id}`,
      state: {
        id: task?.id,
        title: task?.title,
        content: task?.content,
        items: task?.items,
        examState,
        assessmentSelectedTeacher,
      },
    });
  };

  return (
    <div className="main">
      <div className="main-content">
        <TitlePage
          title={exams?.title != null ? exams?.title: "Sem Titulo"}
          subtitle={`Código: ${exams?.code}`}
          description={exams?.description}
          goBackTo={`/admin/assessments/${idAssessment}/`}
          stateRouteData={{ ...examState }}
        />
        <Searchbar
          elementsToFilter={tasks}
          setFilterResultFunction={setTasksFiltered}
          filterField={"title"}
          specificFilterFunction={undefined}
          titleTable="Tarefas"
          setTextSearch={setSearchText}
          // numTotalRecordsTable={examTasks.length + " registro(s) encontrado(s)"}
          filterVisible={true}

        />

        {tasksFiltered.length > 0 ?
          <div>
            {tasksFiltered.map((task) => {

              return (
                <>
                  <CardItens
                    color={"primary"}
                    afterItens={"Tarefa "}
                    task={task?.items.length + " Itens"}
                    title={task.title}
                    button={<Button
                      variant="outlined" endIcon={<ArrowForwardIcon />}
                      onClick={(event: any) => handleClick(event, task)}>
                      Visualizar
                    </Button>}
                  />
                </>

              );
            })
            }

            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div className="" style={{ marginLeft: "10px" }}>1 a {tasksFiltered.length} de {tasksFiltered.length} registros</div>
              <div></div>
              <div className="" style={{ marginTop: "30px", paddingLeft: "10px", marginRight: "10px" }}>
                <Stack >
                  <Pagination variant="outlined" shape="rounded" style={{ marginBottom: "32px" }}
                    count={1}
                    page={page}
                    onChange={(e, value) => handleChangeRowsPerPage(value)}
                  />
                </Stack>
              </div>
            </div>

          </div>
          : <div>
            <TableEmptyState text={searchText} />
          </div>
        }
      </div>

    </div>

  );
}
