import "./main.css";
import { Route, Switch } from "react-router-dom";
import Admin from "pages/Admin";

const OuterRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" component={Admin} />
    </Switch>
  );
};
export default OuterRoutes;
