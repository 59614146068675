import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import ChangePasswordForm from "./ChangePasswordForm";

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
}

export default function ChangePassword({
  isOpen = true,
  onClose = () => {},
}: Props) {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth   >
      <DialogTitle>Alterar Senha</DialogTitle>
      <DialogContent>
        <ChangePasswordForm onSubmitForm={onClose} />
      </DialogContent>
    </Dialog>
  );
}
