import { createReducer } from "@reduxjs/toolkit";
import { clearValues, setAssessmentList, setAssessmentReducer, setExamList, setExamReducer, setItemList, setItemReducer, setTaskList, setTaskReducer, } from "./Actions";



export interface IItemsSelected {
    itemsSelectedReducer: {
        assessmentList: any[];
        examList: any[];
        taskList: any[];
        itemList: any[];
        assessment: any;
        exam: any;
        task: any;
        item: any;
    }

}

const initialState: IItemsSelected = {
    itemsSelectedReducer: {
        assessmentList: [],
        examList: [],
        taskList: [],
        itemList: [],
        assessment: null,
        exam: null,
        task: null,
        item: null,
    }

};



export default createReducer(initialState, {

    [setAssessmentReducer.type]: (state, action) => {
        state.itemsSelectedReducer.assessment = action.payload;
    },

    [setExamReducer.type]: (state, action) => {
        state.itemsSelectedReducer.exam = action.payload;
    },

    [setTaskReducer.type]: (state, action) => {
        state.itemsSelectedReducer.task = action.payload;
    },

    [setItemReducer.type]: (state, action) => {
        state.itemsSelectedReducer.item = action.payload;
    },

    [setAssessmentList.type]: (state, action) => {
        state.itemsSelectedReducer.assessmentList = action.payload;
    },

    [setTaskList.type]: (state, action) => {
        state.itemsSelectedReducer.taskList = action.payload;
    },

    [setExamList.type]: (state, action) => {
        state.itemsSelectedReducer.examList = action.payload;
    },

    [setItemList.type]: (state, action) => {
        state.itemsSelectedReducer.itemList = action.payload;
    },





    [clearValues.type]: () => ({ ...initialState }),
});


// export const setAssessmentAction = setAssessment;
// export const setExamAction = setExam;
// export const setTaskAction = setTask;
// export const setItemAction = setItem;
// export default itemsSelectedSession;
