import QRCode from "react-qr-code";

import { MarkdownConvert } from 'components/MarkdownConvert'

import { Item } from 'services/admin/Interfaces/Types'

import './styles.css'

interface IItemEvaluation { item: Item, num_item: number, id_exam: number; id_assessment: number; }

const ALPHABET = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

export function ItemEvaluation({ item, num_item, id_assessment, id_exam }: IItemEvaluation) {
    const QTD_LINHAS_RESPOSTA = 4;

    return (
        <div className="content_item_evaluation" id={`question_${num_item}`}>
            <span className="num_item" id={`head_question_${num_item}`}>Questão {num_item}</span>

            <div id={`content_question_${num_item}`}>
                { item.content && <MarkdownConvert textInMarkdown={item.content} /> }
            </div>

            <div className='area_qr_code'>
                <QRCode
                    value={`${id_assessment};${id_exam};${item.id}`}
                    size={50}
                    bgColor={"#ffffff"}
                    fgColor={"#202020"}
                    level={"L"}
                />
            </div>

            {
                item.choices.length > 0
                ?
                    <div className="alternatives" id={`alternatives_question_${num_item}`}>
                        {
                            item.choices && item.choices.map((item, index) => (
                                <div className="alternative" id={`alternative_question_${item.id}`}>
                                    <div className="letter_question">{ALPHABET[index]} &#41; </div>
                                    
                                    <div style={{ width: '100%' }}>
                                        <MarkdownConvert textInMarkdown={item.content} />
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                :
                    <>
                        {
                            QTD_LINHAS_RESPOSTA > 0 &&
                            <div className='lines_answer' id={`lines_answer_${num_item}`}>
                                {
                                    Array.from({ length: QTD_LINHAS_RESPOSTA }).map(() => <hr />)
                                }
                            </div>
                        }
                    </>
            }
        </div>
    )
}