// GroupsServices.getByGroupType("school").then(({ data }) => {
//     dispatch(getSchools(data));
//   });

import { Group } from "services/admin/Interfaces/Types";
import api from "services/api";

interface Config{
  parent?: number|string;
}

export default class GroupsServices {
  public static async getByGroupType(type = "school", config: Config = {}) {
    return api.get<Group[]>("groups/groups/", {
      params: {
        type,
        ...config
      },
    });
  }
}
