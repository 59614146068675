import { Alert, Box, Button, Card, CardContent, Divider, Link, Pagination, Stack, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useEffect, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Chip from '@mui/material/Chip';

export default function CardItens(props: any) {

    const [colorCard, setColorCard] = useState<string>("#CACDD5")


    const calculaPage = (value) => {
        if (value > 10) {
            return Math.floor(value / 10);
        }
        return 1

    }

    useEffect(() => {
        if (props.color === "success") {
            setColorCard("#30BC24")
        } else if (props.color === "warning") {
            setColorCard("#F5A508")
        } else if (props.color === "primary") {
            setColorCard("#1976d2")
        } else {

        }
    }, []);



    return (
        <div style={{ padding: "10px" }}>
            <Card variant="outlined" style={{ borderLeft: `7px solid ${colorCard}` }}>
                <CardContent style={{ height: "166px", backgroundColor: "#F8F9FC" }}>
                    <div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "16px", height: "36px" }}>
                            <div style={{ display: "flex", alignItems: "center", }}>
                                <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                                    {props?.afterItens} {props?.index}
                                </Typography>
                                <Divider style={{ marginLeft: "16px", marginRight: "16px" }} orientation="vertical" variant="middle" flexItem />
                                <Typography sx={{ fontSize: "16px" }}>
                                    {props?.task}
                                </Typography>
                                {/* <Divider style={{ marginLeft: "16px", marginRight: "16px" }} orientation="vertical" variant="middle" flexItem /> */}



                                {/* <Chip color="warning" icon={<CheckCircleIcon />} label="Call me"  style={{ padding: "0px, 12px, 0px, 12px", border: "1px", borderRadius: "20px", fontSize: "16px", fontWeight: 500 }}/> */}

                            </div>
                            <div>
                                {props?.button}
                            </div>

                        </div>
                        <Divider style={{ marginBottom: "16px" }} />
                        <div>

                            <div style={{}}>
                                <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                                    Título
                                </Typography>
                                <Typography sx={{ fontSize: "16px", marginTop: "8px" }}>
                                    {props?.title}
                                </Typography>
                            </div>

                        </div>
                    </div>
                </CardContent >
            </Card>
            
        </div>
    );
}