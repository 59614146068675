import React, { useState } from "react";
import ImageEmpty from "../../assets/images/empty-state.svg";

const TableEmptyState = (props: any) => {    
    return (
        <div style={{ width: "350px", height: "386px", position: "absolute", left: "40%", display: "block" }}>
            <img src={ImageEmpty} alt="Image Empty State" height={160} width={163} style={{ display: "block", margin: "0 auto", maxWidth: "100%" }} />
            <div style={{ height: "42px", textAlign: "center", fontSize: "14px" }}>
                <b>Nenhum resultado encontrado para: <br></br><span style={{ color: "#DC189B" }}>"{props.text}"</span></b>
                {/* Nenhum resultado encontrado para "{searchText}" */}
            </div>
            <div style={{ textAlign: "center", fontSize: "14px" }}>
                <p>Por favor, faça uma nova pesquisa para visualizar dados na tabela</p>
            </div>

        </div>
    );
};

export default TableEmptyState;
