import {
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  MenuItem,
  Radio,
  Select,
  Paper,
  TextField,
  ListItemText,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import React, { FC, useState } from "react";
import { Group } from "services/admin/Interfaces/Types";
import GroupsServices from "store/reducers/Admin/AsyncActions/goups";
import AuthenticationServices from "store/reducers/Authentication/AsyncActions/authentication.services";
import _ from "underscore";

interface Student {
  first_name: string;
  id: number;
  last_name: string;
  type: "student";
}

const defaultState = {
  schoolId: -1,
  classId: -1,
  studentFullName: "",
  studentId: -1,
};

interface SelectStudentProps {
  onSelect: (id: number) => void;
}

const SelectStudent: FC<SelectStudentProps> = (props) => {
  const [schools, setSchools] = useState<Group[]>([]);
  const [classes, setClasses] = useState<Group[]>([]);
  const [selected, setSelected] = useState(defaultState);

  const [students, setStudents] = useState<Student[]>([]);

  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    GroupsServices.getByGroupType().then(({ data }) => {
      setSchools(data);
    });
  }, []);

  function onHandleSearcher() {
    setLoading(true);
    AuthenticationServices.getUsers({
      profile__type: "student",
      profile__group: selected.classId,
      full_name: selected.studentFullName,
    })
      .then(({ data }) => {
        const users = (data as any).results as Student[];
        setStudents(_.sortBy(users, (i) => i.first_name.toLowerCase()));
      })
      .finally(() => setLoading(false));
  }

  return (
    <fieldset className="fieldset_info">
      <legend>Identificação de aluno:</legend>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Escola</InputLabel>
            <Select
              fullWidth
              label="Escola"
              required
              variant="outlined"
              defaultValue={-1}
              value={selected.schoolId}
              sx={{ textTransform: "capitalize" }}
              onChange={(event) => {
                const schoolId = event.target.value;
                setSelected({
                  ...defaultState,
                  schoolId: schoolId as number,
                });
                setStudents([]);
                GroupsServices.getByGroupType("class", {
                  parent: schoolId,
                }).then(({ data }) => {
                  setClasses(data);
                });
              }}
            >
              <MenuItem value={-1} disabled>
                Selecione...
              </MenuItem>
              {_.sortBy(schools, (i) => i.name.toLowerCase()).map(
                ({ name, id }) => (
                  <MenuItem
                    sx={{ textTransform: "capitalize" }}
                    key={id}
                    value={id}
                  >
                    {name.toLowerCase()}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Turma</InputLabel>
            <Select
              fullWidth
              label="Turma"
              required
              variant="outlined"
              defaultValue={-1}
              value={selected.classId}
              disabled={selected.schoolId <= 0}
              onChange={(event) => {
                const classId = event.target.value;
                setSelected({
                  ...defaultState,
                  schoolId: selected.schoolId,
                  classId: classId as number,
                });
                setStudents([]);
              }}
            >
              <MenuItem value={-1} disabled>
                Selecione a turma
              </MenuItem>
              {_.sortBy(classes, (i) => i.name.toLowerCase()).map(
                ({ name, id }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={9}>
          <TextField
            disabled={selected.classId <= 0}
            fullWidth
            label="Nome do aluno"
            placeholder="Nome completo do aluno"
            variant="outlined"
            value={selected.studentFullName}
            onChange={(event) => {
              setSelected({ ...selected, studentFullName: event.target.value });
            }}
          />
        </Grid>
        <Grid item alignItems="center" xs={3}>
          <Button
            variant="contained"
            onClick={onHandleSearcher}
            fullWidth
            style={{ marginTop: 8 }}
            disabled={!selected.studentFullName || loading}
          >
            {loading ? <CircularProgress size={24} /> : "Buscar"}
          </Button>
        </Grid>
        {students.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="h6">Selecione o aluno:</Typography>
            <List
              sx={{ bgcolor: "#eeeeeeaa", p: 1, textTransform: "capitalize" }}
            >
              {students.map((student) => {
                return (
                  <Paper key={student.id} square variant="outlined">
                    <ListItemButton
                      onClick={() => {
                        setSelected({ ...selected, studentId: student.id });
                        props.onSelect(student.id);
                      }}
                    >
                      <ListItem
                        secondaryAction={
                          <Radio
                            checked={selected.studentId === student.id}
                            value={student.id}
                            color="primary"
                          />
                        }
                      >
                        <ListItemText>
                          {student.first_name} {student.last_name}
                        </ListItemText>
                      </ListItem>
                    </ListItemButton>
                  </Paper>
                );
              })}
            </List>
          </Grid>
        )}
      </Grid>
    </fieldset>
  );
};

export default SelectStudent;
