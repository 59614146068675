import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "store";
import Routes from "./routes";
import Navbar from "components/Navbar/Navbar";
import Footer from "pages/Admin/sections/Footer";

import { relative } from "path";
import { useAuth } from "@cenpe/auth";
import { useEffect } from "react";

import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@mui/material";
import { theme } from "assets/themes/MainTheme";

import "react-toastify/dist/ReactToastify.css";
import './assets/globalStyles.css'
import "./App.css";

export default function App() {
  const { check } = useAuth.getState();

  function onRedirect() {
    sessionStorage.clear();
  }

  useEffect(() => {
    setInterval(() => {
      check({ withRedirectSSO: true, onRedirect });
    }, 1000);
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <div>
          <BrowserRouter>
            <div>
              {/* <Navbar /> */}

              <Navbar
                titleApp="Administração de Turmas"
                itemsOfNavigation={[
                  {
                    name: 'Início',
                    route: '/',
                    isSelected: true,
                    isExclusive: true,
                  },
                  {
                    name: 'Avaliações',
                    route: '/admin/assessments',
                    isSelected: false
                  },
                  {
                    name: 'Validações',
                    route: '/admin/transcriptions',
                    isSelected: false
                  },                  
                ]}
              />
              <Routes />
              <ToastContainer
                position="top-right"
                theme="colored"
                autoClose={2000}
              />
            </div>
          </BrowserRouter>
        </div>
      </ThemeProvider>
    </Provider>
  );
}
