import { configureStore } from '@reduxjs/toolkit';

import authentication from './reducers/Authentication';
import admin from './reducers/Admin'
import transcriberAssessment from './reducers/Assessment';
import itemsSelectedSession from './reducers/ItemsSelected';

export default configureStore({
    reducer: {
        authentication: authentication,
        admin: admin,
        transcriberAssessment: transcriberAssessment,
        itemsSelectedReducer: itemsSelectedSession,
		  
    }
})

